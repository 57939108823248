import React from "react";

type Props = {
    children: React.ReactNode;
    size?: "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl";
    isOpen: boolean;
    toggleModal: () => void;
};

const Modal = ({ children, size = "xl", isOpen, toggleModal }: Props) => {
    return isOpen ? (
        <div className="fixed top-0 bottom-0 left-0 right-0 z-50 flex overflow-auto">
            {/* overlay */}
            <div
                onClick={() => toggleModal()}
                className="fixed top-0 bottom-0 left-0 right-0 -z-10 bg-black bg-opacity-20"
            ></div>
            {/* body */}
            <div
                className={`m-auto w-full ${
                    size === "sm"
                        ? "max-w-sm"
                        : size === "md"
                        ? "max-w-md"
                        : size === "lg"
                        ? "max-w-lg"
                        : size === "xl"
                        ? "max-w-xl"
                        : size === "2xl"
                        ? "max-w-2xl"
                        : size === "3xl"
                        ? "max-w-3xl"
                        : size === "4xl"
                        ? "max-w-4xl"
                        : size === "5xl"
                        ? "max-w-5xl"
                        : ""
                } bg-white rounded-md p-4`}
            >
                {children}
            </div>
        </div>
    ) : (
        <></>
    );
};

export default Modal;
