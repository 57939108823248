import { useState, useEffect } from "react";
import { useUser } from "../../context/UserContext";

import { Axios } from "../../utils/axios";
import Loader from "../../Components/Loader/Loader";
import BlogCard from "../../Components/EditComponents/BlogCard";
import AddBlog from "../../Components/AddBlog";
import { toast } from "react-toastify";
import { toast_error_settings } from "../../utils/constants";

type Props = {};

const AdminBlogs = (props: Props) => {
    const [data, setData] = useState<any>("loading");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { userState, setUser } = useUser();

    const fetchData = async () => {
        if (!isLoading) {
            setIsLoading(true);
        }

        Axios.post("build_pages", {
            adminid: userState?.admin_id,
            token: userState?.admin_login_token,
            page_id: 7,
        })
            .then((res) => {
                if (res.data.length === 0) {
                    throw new Error("Nothing returned");
                }
                setData(res.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setData(err.message);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userState]);

    if (data === "loading") {
        return <Loader />;
    }

    const handleSubmit = async (
        blog_id: string | number,
        title?: string,
        description?: string,
        image?: string
    ) => {
        setIsLoading(true);
        let isDelete: boolean = false;

        if (blog_id && !image && !title && !description) {
            isDelete = true;
        }

        const url: string = isDelete ? "delete_blog" : "update_blog";

        const postedData = {
            adminid: userState?.admin_id,
            token: userState?.admin_login_token,
            blog_id: blog_id,
            title: title,
            description: description,
            featured_image: image,
            blog_banner: image,
            short_description: description?.slice(0, 200) || "...",
            date: new Date().toLocaleString(),
        };

        try {
            const res = await Axios.post(url, postedData);
            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }
    };

    const handleNormalSubmit = async (
        submittedData: {
            component_title?: string;
            component_description?: string;
        },
        sectionKey: string,
        updating_component_id: string | number
    ) => {
        setIsLoading(true);
        const updated_components = data[sectionKey].map((item: any) => {
            if (
                item.component_id.toString() ===
                updating_component_id.toString()
            ) {
                return {
                    ...item,
                    component_title: submittedData.component_title,
                    component_description:
                        submittedData.component_description || "",
                };
            } else {
                return {
                    ...item,
                    component_title: item.title,
                    component_description: item.description || "",
                };
            }
        });

        try {
            const res = await Axios.post("update_component", {
                adminid: userState?.admin_id,
                token: userState?.admin_login_token,
                components: updated_components,
            });
            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }
    };

    return (
        <div>
            {isLoading && <Loader withBg={true} />}

            <div className="grid grid-cols-2 lg:grid-cols-3 gap-6">
                {data["blogs"].map((blog: any) => (
                    <div key={blog.blog_id}>
                        <BlogCard
                            blog_id={blog.blog_id}
                            title={blog.title}
                            description={blog.description}
                            image={blog.featured_image}
                            handleUpdate={handleSubmit}
                        />
                    </div>
                ))}
            </div>
            <br />

            <AddBlog
                fetchData={fetchData}
                setLoading={() => setIsLoading(true)}
            />

            <hr className="my-10" />
        </div>
    );
};

export default AdminBlogs;
