import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import Header from "../Shared/Header";

import { MdManageSearch, MdPointOfSale } from "react-icons/md";
import { BsNutFill, BsQuestion, BsShop } from "react-icons/bs";
import { CgProfile, CgShoppingBag } from "react-icons/cg";
import { AiOutlineSchedule } from "react-icons/ai";
import { TbPoint } from "react-icons/tb";

type Props = {
    children: React.ReactNode;
};

const MobileMainLayout = ({ children }: Props) => {
    const [sideNavOpen, setSideNavOpen] = useState<boolean>(true);

    const toggleSideNav = () => {
        setSideNavOpen((p) => !p);
    };

    return (
        <>
            <Header toggleSideNav={toggleSideNav} />
            <div
                className="flex overflow-auto"
                style={{ height: "calc(100vh - 96px)" }}
            >
                <aside
                    className={`h-full overflow-y-auto overflow-x-hidden w-fit ${sideNavOpen ? "w-72" : "w-14 text-xl text-center"
                        } shadow-xl border-r transition-[width]`}
                >
                    {/* <div className="border-b px-4 py-2 font-bold">
                        <div className="flex items-center gap-2">
                            <RiPagesLine />
                            <span className={`${sideNavOpen ? "" : "hidden"} `}>
                                Pages
                            </span>
                        </div>
                    </div> */}

                    <NavLink
                        className="block px-4 py-1 mt-2"
                        to="/mobile-app/locations"
                    >
                        <div className="flex items-center gap-2">
                            <BsShop />
                            <span className={`${sideNavOpen ? "" : "hidden"} `}>
                                Manage Store
                            </span>
                        </div>
                    </NavLink>


                    <hr className="my-3" />

                    {/* <NavLink
                        className="block px-4 py-1 mt-2"
                        to="/mobile-app/menus"
                    >
                        <div className="flex items-center gap-2">
                            <BsShop />
                            <span className={`${sideNavOpen ? "" : "hidden"} `}>
                                Menus
                            </span>
                        </div>
                    </NavLink>

                    <NavLink
                        className="block px-4 py-1 mt-2"
                        to="/mobile-app/wifi-codes"
                    >
                        <div className="flex items-center gap-2">
                            <BsWifi />
                            <span className={`${sideNavOpen ? "" : "hidden"} `}>
                                Wifi codes
                            </span>
                        </div>
                    </NavLink>

                    <NavLink
                        className="block px-4 py-1 mt-2"
                        to="/mobile-app/merchandise"
                    >
                        <div className="flex items-center gap-2">
                            <TbShirt />
                            <span className={`${sideNavOpen ? "" : "hidden"} `}>
                                Merchandise
                            </span>
                        </div>
                    </NavLink>

                    <NavLink
                        className="block px-4 py-1 mt-2"
                        to="/mobile-app/spaces"
                    >
                        <div className="flex items-center gap-2">
                            <MdWorkspacesOutline />
                            <span className={`${sideNavOpen ? "" : "hidden"} `}>
                                Spaces
                            </span>
                        </div>
                    </NavLink>

                    <hr className="my-3" /> */}

                    <NavLink
                        className="block px-4 py-1 mt-2"
                        to="/mobile-app/bookings"
                    >
                        <div className="flex items-center gap-2">
                            <AiOutlineSchedule />
                            <span className={`${sideNavOpen ? "" : "hidden"} `}>
                                Bookings
                            </span>
                        </div>
                    </NavLink>

                    <NavLink
                        className="block px-4 py-1 mt-2"
                        to="/mobile-app/orders"
                    >
                        <div className="flex items-center gap-2">
                            <CgShoppingBag />
                            <span className={`${sideNavOpen ? "" : "hidden"} `}>
                                Orders
                            </span>
                        </div>
                    </NavLink>

                    <NavLink
                        to={`/mobile-app/generated-points`}
                        className="block px-4 py-1 mt-2"
                    >
                        <div className="flex items-center gap-2">
                            <TbPoint />
                            <span className={`${sideNavOpen ? "" : "hidden"} `}>
                                Generated Points
                            </span>
                        </div>
                    </NavLink>

                    <NavLink
                        to={`/mobile-app/users/all`}
                        className="block px-4 py-1 mt-2"
                    >
                        <div className="flex items-center gap-2">
                            <CgProfile />
                            <span className={`${sideNavOpen ? "" : "hidden"} `}>
                                Users
                            </span>
                        </div>
                    </NavLink>

                    <NavLink
                        to={`/mobile-app/nft-benefits/all`}
                        className="block px-4 py-1 mt-2"
                    >
                        <div className="flex items-center gap-2">
                            <BsNutFill />
                            <span className={`${sideNavOpen ? "" : "hidden"} `}>
                                NFT Benefits
                            </span>
                        </div>
                    </NavLink>


                    <NavLink
                        to={`/mobile-app/nft-questions/all`}
                        className="block px-4 py-1 mt-2"
                    >
                        <div className="flex items-center gap-2">
                            <BsQuestion />
                            <span className={`${sideNavOpen ? "" : "hidden"} `}>
                                NFT Questions
                            </span>
                        </div>
                    </NavLink>

                </aside>
                <main className="p-8 flex-1 max-h-full overflow-auto relative">
                    {children}
                </main>
            </div>
        </>
    );
};

export default MobileMainLayout;
