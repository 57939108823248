import React, { useState } from "react";

import { useUser } from "../context/UserContext";

import Button from "./UI/Button";
import Modal from "./UI/Modal";
import Dropzone from "./Dropzone";

import EditWrapper from "./EditWrapper";
import { Axios } from "../utils/axios";
import { MEDIA_URL, toast_error_settings } from "../utils/constants";
import { toast } from "react-toastify";

type Props = {
    title: string;
    text: string;
    image: string;
    component_id: string | number;
    sectionKey: string;
    setIsLoading: (state: boolean) => void;
    fetchData: () => void;
    storyItem: any;
};

const StoryEdit = ({
    image,
    text,
    title,
    setIsLoading,
    fetchData,
    storyItem,
}: Props) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [uploadedImg, setUploadedImg] = useState<string>(image);

    const { userState, setUser } = useUser();

    const toggleModal = () => {
        setModalOpen((p) => !p);
    };

    const toggleDeleteModal = () => {
        setDeleteModalOpen((p) => !p);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const data = Object.values(e.target).reduce((obj, field) => {
            obj[field.name] = field.value;
            return obj;
        }, {});

        try {
            const res = await Axios.post("update_stories", {
                adminid: userState?.admin_id,
                token: userState?.admin_login_token,
                ...storyItem,
                title: data.title,
                description: data.description,
                image: uploadedImg,
            });
            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }
        toggleModal();
    };

    const handleDeleteSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            const res = await Axios.post("delete_stories", {
                adminid: userState?.admin_id,
                token: userState?.admin_login_token,
                ...storyItem,
            });
            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }
        toggleDeleteModal();
    };

    return (
        <>
            <Modal size="3xl" isOpen={modalOpen} toggleModal={toggleModal}>
                <form className="mt-6" onSubmit={handleSubmit}>
                    <div className="input-row">
                        <Dropzone
                            defaultValue={image}
                            handleIconUpload={(src: string) =>
                                setUploadedImg(src)
                            }
                            previewClass="w-full"
                        />
                    </div>

                    {title && (
                        <div className="input-row">
                            <input
                                name="title"
                                type="text"
                                placeholder="Title *"
                                defaultValue={title}
                                required
                            />
                        </div>
                    )}

                    {text && (
                        <div className="input-row">
                            <textarea
                                name="description"
                                placeholder="Description *"
                                defaultValue={text}
                                rows={4}
                                required
                            />
                        </div>
                    )}

                    <div className="flex space-x-4">
                        <Button
                            color="transparent"
                            className="ml-auto mt-8"
                            type="button"
                            onClick={() => toggleModal()}
                        >
                            Cancel
                        </Button>
                        <Button className="ml-auto mt-8" type="submit">
                            Save
                        </Button>
                    </div>
                </form>
            </Modal>

            <Modal isOpen={deleteModalOpen} toggleModal={toggleDeleteModal}>
                <form className="mt-6" onSubmit={handleDeleteSubmit}>
                    <h4 className="text-center text-lg font-[600]">
                        Are you sure you want to delete it?
                    </h4>
                    <div className="flex space-x-4">
                        <Button
                            className="ml-auto mt-8"
                            type="button"
                            onClick={() => toggleDeleteModal()}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="ml-auto mt-8 !bg-red-600 !border-red-600"
                            type="submit"
                        >
                            Delete
                        </Button>
                    </div>
                </form>
            </Modal>
            <EditWrapper onDelete={toggleDeleteModal} toggleModal={toggleModal}>
                <div className="flex gap-4">
                    <div className="flex-[0.4]">
                        <img src={MEDIA_URL + image} alt="" />
                    </div>
                    <div className="mt-2 flex-1">
                        <h2
                            className="font-bold text-xl mb-2"
                            dangerouslySetInnerHTML={{
                                __html: title,
                            }}
                        />
                        <p
                            className="whitespace-pre-wrap"
                            dangerouslySetInnerHTML={{ __html: text }}
                        />
                    </div>
                </div>
            </EditWrapper>
        </>
    );
};

export default StoryEdit;
