import { useEffect } from "react";

import { useUser } from "./context/UserContext";
import { Axios } from "./utils/axios";

import { toast } from "react-toastify";
import { toast_error_settings } from "./utils/constants";

import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";



import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import AdminHome from "./pages/AdminPages/Home";
import AdminSpaces from "./pages/AdminPages/Spaces";
import AdminCommunity from "./pages/AdminPages/CommunityEvents";
import MainLayout from "./Components/Layout/MainLayout";
import AdminServices from "./pages/AdminPages/ServicesProviding";
import AdminMerch from "./pages/AdminPages/Merchandise";
import AdminBlogs from "./pages/AdminPages/Blogs";
import NftCollectionAdmin from "./pages/AdminPages/NftCollection";
import MobileMainLayout from "./Components/Layout/MobileMainLayout";
import AdminLocations from "./pages/mobile-app/locations";
import AdminMobileSpaces from "./pages/mobile-app/spaces/index";
import WifiCodes from "./pages/mobile-app/wifi-codes";
import SpacesByBranchId from "./pages/mobile-app/spaces/branch_id";
import AdminMobileMerch from "./pages/mobile-app/merchandise/index";
import AdminMerchByBranch from "./pages/mobile-app/merchandise/country_id";
import AdminMobileMenu from "./pages/mobile-app/menu";
import AdminMenuByCountry from "./pages/mobile-app/menu/country_id";
import BookingsByRoomId from "./pages/mobile-app/spaces/room_id";
import Bookings from "./pages/mobile-app/bookings";
import CategoriesByCountry from "./pages/mobile-app/categories";
import ManageAddons from "./pages/mobile-app/manage-addons";
import AdminOrders from "./pages/mobile-app/orders"
import AdminOrderDetails from "./pages/mobile-app/AdminOrderDetails";
import EventsbyBranch from "./pages/events/branch_id";
import AdminMobileEvents from "./pages/mobile-app/events/branch_id";
import MobileAnnouncements from "./pages/mobile-app/announcement";
import AdminUsersByCountryId from "./pages/mobile-app/users/country_id";
import AdminNftQuestions from "./pages/mobile-app/nft-questions/country_id";
import AdminNftBenefits from "./pages/mobile-app/nft-benefits/country_id";
import AdminGeneratedPoints from "./pages/mobile-app/generated-points";
import Notifications from "./pages/mobile-app/notifications";

type Props = {};

const App = (props: Props) => {
    const { userState, setUser } = useUser();
    let isAuthenticated = true;
    isAuthenticated = userState !== null;

    useEffect(() => {
        const verifyToken = async () => {
            try {
                const res = await Axios.post("get_all_sliders", {
                    adminid: userState?.admin_id,
                    token: userState?.admin_login_token,
                });
                if (res.data === "token error") {
                    throw new Error(res.data);
                }
            } catch (err: any) {
                console.error(err);
                toast.error(
                    `An error has occured! ${err.message}`,
                    toast_error_settings
                );
                setUser(null);
            }
        };
        if (userState !== null) {
            verifyToken();
        }
    }, [setUser, userState]);

    return (
        <BrowserRouter>
            {isAuthenticated ? (
                <>
                    <Routes>
                        <Route
                            path="/dashboard"
                            element={
                                <MainLayout>
                                    <Dashboard />
                                </MainLayout>
                            }
                        />
                        <Route path="/pages">
                            <Route
                                path="home"
                                element={
                                    <MainLayout>
                                        <AdminHome />
                                    </MainLayout>
                                }
                            />
                            <Route
                                path="spaces"
                                element={
                                    <MainLayout>
                                        <AdminSpaces />
                                    </MainLayout>
                                }
                            />
                            <Route
                                path="community-events"
                                element={
                                    <MainLayout>
                                        <AdminCommunity />
                                    </MainLayout>
                                }
                            />
                            <Route
                                path="services"
                                element={
                                    <MainLayout>
                                        <AdminServices />
                                    </MainLayout>
                                }
                            />
                            <Route
                                path="merchandise"
                                element={
                                    <MainLayout>
                                        <AdminMerch />
                                    </MainLayout>
                                }
                            />
                            <Route
                                path="nft-collection"
                                element={
                                    <MainLayout>
                                        <NftCollectionAdmin />
                                    </MainLayout>
                                }
                            />

                            <Route
                                path="blogs"
                                element={
                                    <MainLayout>
                                        <AdminBlogs />
                                    </MainLayout>
                                }
                            />
                        </Route>

                        <Route path="/mobile-app">
                            <Route
                                path=""
                                element={
                                    <Navigate to="/mobile-app/locations" />
                                }
                            />

                            <Route
                                path="locations"
                                element={
                                    <MobileMainLayout>
                                        <AdminLocations />
                                    </MobileMainLayout>
                                }
                            />

                            <Route
                                path="announcement/:branch_id"
                                element={
                                    <MobileMainLayout>
                                        <MobileAnnouncements />
                                    </MobileMainLayout>
                                }
                            />

                            <Route
                                path="wifi-codes/:branch_id"
                                element={
                                    <MobileMainLayout>
                                        <WifiCodes />
                                    </MobileMainLayout>
                                }
                            />

                            <Route
                                path="events/:branch_id"

                                element={
                                    <MobileMainLayout>
                                        <AdminMobileEvents />
                                    </MobileMainLayout>
                                }
                            />

                            <Route
                                path="spaces"
                                element={
                                    <MobileMainLayout>
                                        <AdminMobileSpaces />
                                    </MobileMainLayout>
                                }
                            />

                            <Route
                                path="spaces/:branch_id"
                                element={
                                    <MobileMainLayout>
                                        <SpacesByBranchId />
                                    </MobileMainLayout>
                                }
                            />

                            <Route
                                path="spaces/:branch_id/:room_id"
                                element={
                                    <MobileMainLayout>
                                        <BookingsByRoomId />
                                    </MobileMainLayout>
                                }
                            />


                            <Route
                                path="wifi-codes"
                                element={
                                    <MobileMainLayout>
                                        <AdminMobileSpaces />
                                    </MobileMainLayout>
                                }
                            />


                            <Route
                                path="users/:branch_id/"
                                element={
                                    <MobileMainLayout>
                                        <AdminUsersByCountryId />
                                    </MobileMainLayout>
                                }
                            />

                            <Route
                                path="nft-questions/:branch_id/"
                                element={
                                    <MobileMainLayout>
                                        <AdminNftQuestions />
                                    </MobileMainLayout>
                                }
                            />

                            <Route
                                path="nft-benefits/:country_id/"
                                element={
                                    <MobileMainLayout>
                                        <AdminNftBenefits />
                                    </MobileMainLayout>
                                }
                            />



                            <Route
                                path="merchandise"
                                element={
                                    <MobileMainLayout>
                                        <AdminMobileMerch />
                                    </MobileMainLayout>
                                }
                            />
                            <Route
                                path="merchandise/:country_id"
                                element={
                                    <MobileMainLayout>
                                        <AdminMerchByBranch />
                                    </MobileMainLayout>
                                }
                            />

                            <Route
                                path="generated-points"
                                element={
                                    <MobileMainLayout>
                                        <AdminGeneratedPoints />
                                    </MobileMainLayout>
                                }
                            />

                            <Route
                                path="menus"
                                element={
                                    <MobileMainLayout>
                                        <AdminMobileMenu />
                                    </MobileMainLayout>
                                }
                            />
                            <Route
                                path="menus/:country_id"
                                element={
                                    <MobileMainLayout>
                                        <AdminMenuByCountry />
                                    </MobileMainLayout>
                                }
                            />


                            <Route
                                path="events/:branch_id"
                                element={
                                    <MobileMainLayout>
                                        <AdminMobileEvents />
                                    </MobileMainLayout>
                                }
                            />

                            <Route
                                path="categories/:country_id"
                                element={
                                    <MobileMainLayout>
                                        <CategoriesByCountry />
                                    </MobileMainLayout>
                                }
                            />

                            <Route
                                path="addons/:country_id"
                                element={
                                    <MobileMainLayout>
                                        <ManageAddons />
                                    </MobileMainLayout>
                                }
                            />

                            <Route
                                path="notifications/:country_id"
                                element={
                                    <MobileMainLayout>
                                        <Notifications />
                                    </MobileMainLayout>
                                }
                            />


                            <Route
                                path="bookings"
                                element={
                                    <MobileMainLayout>
                                        <Bookings />
                                    </MobileMainLayout>
                                }
                            />


                            <Route
                                path="orders"
                                element={
                                    <MobileMainLayout>
                                        <AdminOrders />
                                    </MobileMainLayout>
                                }
                            />

                            <Route
                                path="orders/:order_id"
                                element={
                                    <MobileMainLayout>
                                        <AdminOrderDetails />
                                    </MobileMainLayout>
                                }
                            />

                        </Route>

                        <Route
                            path="*"
                            element={<Navigate to="/pages/home" />}
                        />
                    </Routes>
                </>
            ) : (
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="*" element={<Navigate to="/login" />} />
                </Routes>
            )}
        </BrowserRouter>
    );
};

export default App;
