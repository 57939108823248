import { FormEvent, useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";

import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Dropzone from "../../../Components/Dropzone";
import Loader from "../../../Components/Loader/Loader";
import Button from "../../../Components/UI/Button";
import Modal from "../../../Components/UI/Modal";
import { useUser } from "../../../context/UserContext";
import { MEDIA_URL, toast_error_settings } from "../../../utils/constants";
import { mobileAxios } from "../../../utils/mobileAxios";

import { Button as MUIButton } from "@mui/material";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import dayjs from "dayjs";

type Props = {};

function descendingComparator(a: any, b: any, orderBy: any) {
    if (orderBy === "total_price") {
        if (parseFloat(b[orderBy]) < parseFloat(a[orderBy])) {
            return -1;
        }
        if (parseFloat(b[orderBy]) > parseFloat(a[orderBy])) {
            return 1;
        }
        return 0;
    } else if (orderBy === "id" || orderBy === "statys") {
        return b[orderBy].localeCompare(a[orderBy]);
    } else if (orderBy === "created_date") {
        return dayjs(b[orderBy]).isAfter(dayjs(a[orderBy])) ? -1 : 1;
    } else {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
}

function getComparator(order: any, orderBy: any) {
    return order === "desc"
        ? (a: any, b: any) => descendingComparator(a, b, orderBy)
        : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

const headCells = [
    {
        id: "id",
        label: "ID",
    },
    {
        id: "name",
        label: "Name",
    },
    {
        id: "email",
        label: "Email",
    },
    {
        id: "type",
        label: "Type",
    },
    {
        id: "level",
        label: "Level",
    },
    {
        id: "profile",
        label: "Profile",
    },
    {
        id: "active",
        label: "Is Active",
    },
    {
        id: "created_date",
        label: "Created Date",
    },

    {
        id: "action",
        label: "",
    },
];

function EnhancedTableHead(props: any) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: any) => (event: any) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className="whitespace-nowrap">
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        padding={"normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                            className="font-semibold text-base"
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const AdminUsersByCountryId = (props: Props) => {
    const [data, setData] = useState<any>("loading");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [uploadedImg, setUploadedImg] = useState<string | undefined>(
        undefined
    );
    const [addPointsModal, setAddPointsModal] = useState(null);
    const [inputSearchText, setInputSearchText] = useState("");

    const [rows, setRows] = useState([]);

    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("created_date");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleRequestSort = (event: any, property: any) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const [allCategories, setAllCategories] = useState([]);

    const [editMerchModal, setEditMerchModal] = useState<{
        details: any;
        variations: any[];
    } | null>(null);

    const [newVariationOpen, setNewVariationOpen] = useState(false);

    const [addMerchModal, setAddMerchModal] = useState<boolean>(false);
    const [deleteModal, setDeleteModal] = useState<string | null>(null);

    const toggleAddMerchModal = () => {
        setAddMerchModal((p) => !p);
    };

    const { userState } = useUser();

    const params = useParams();

    const fetchData = async () => {
        if (!isLoading) {
            setIsLoading(true);
        }

        mobileAxios(`users`, {
            method: "POST",
            data: {
                adminId: userState?.admin_id_mobile,
            },
            headers: {
                Authorization: `Bearer ${userState?.admin_login_token_mobile}`,
            },
        })
            .then((res) => {
                const tmp_data = res.data?.sort(
                    (a: any, b: any) =>
                        new Date(b.created_date).getTime() -
                        new Date(a.created_date).getTime()
                );
                console.log(res.data);

                const rowsData = res.data?.map((item: any) => ({
                    id: item._id,
                    created_date: item.created_date,
                    name: item.name,
                    email: item.email,
                    level: item.level,
                    type: item.type,
                    active: item.active,
                    profile: item.profile,
                }));
                // item.status

                setRows(rowsData);

                setData(tmp_data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setData(err.message);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchData();

        mobileAxios
            .get(`categories`)
            .then((res) => {
                setAllCategories(
                    res.data.filter((item: any) => item.type === "merch")
                );
            })
            .catch((err) => {
                console.error(err);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userState]);

    if (data === "loading") {
        return <Loader />;
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);

        const formData = Object.values(e.target).reduce((obj, field) => {
            obj[field.name] = field.value;
            return obj;
        }, {});

        try {
            const res = await mobileAxios("merchandises", {
                method: "POST",
                data: {
                    adminId: userState?.admin_id_mobile,
                    country: params.country_id,
                    title: formData.title,
                    category: formData.category,
                    image: uploadedImg,
                    description: formData.description,
                },
                headers: {
                    Authorization: `Bearer ${userState?.admin_login_token_mobile}`,
                },
            });
            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }

        setIsLoading(false);
        toggleAddMerchModal();
    };

    const handleMerchDelete = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const res = await mobileAxios(`merchandises/${deleteModal}`, {
                method: "DELETE",
                data: {
                    adminId: userState?.admin_id_mobile,
                },
                headers: {
                    Authorization: `Bearer ${userState?.admin_login_token_mobile}`,
                },
            });
            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }

        setIsLoading(false);
        setDeleteModal(null);
    };

    const openProductDetails = async (merchId: string) => {
        setIsLoading(true);

        await mobileAxios
            .get(`merchandises/${merchId}`)
            .then((res) => {
                setUploadedImg(res.data?.[0].image);
                setEditMerchModal({
                    details: res?.data?.[0],
                    variations: res?.data?.[1],
                });
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setIsLoading(false);
            });
        setIsLoading(false);
    };

    const handleEditSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);

        const formData = Object.values(e.target).reduce((obj, field) => {
            obj[field.name] = field.value;
            return obj;
        }, {});

        setIsLoading(true);

        try {
            // update product details
            const productRes = await mobileAxios(
                `merchandises/${editMerchModal?.details?._id}`,
                {
                    method: "PATCH",
                    data: {
                        adminId: userState?.admin_id_mobile,
                        category: formData.category,
                        image: uploadedImg,
                        title: formData.title,
                        description: formData.description,
                    },

                    headers: {
                        Authorization: `Bearer ${userState?.admin_login_token_mobile}`,
                    },
                }
            );

            const newVarData: any = {};
            Object.keys(formData).forEach((k) => {
                if (k.includes("new")) {
                    newVarData[k?.split("-new")[0]?.split("variation-")[1]] =
                        formData[k];
                }
            });

            // add new variation
            let newVarRes = { data: "no" };
            if (newVarData?.size) {
                newVarRes = await mobileAxios(`merchandises/variations`, {
                    method: "POST",
                    data: {
                        adminId: userState?.admin_id_mobile,
                        merchandises: editMerchModal?.details?._id,
                        ...newVarData,
                    },

                    headers: {
                        Authorization: `Bearer ${userState?.admin_login_token_mobile}`,
                    },
                });
            }

            if (
                productRes.data === "token error" ||
                newVarRes.data === "token error"
            ) {
                throw new Error(productRes.data || newVarRes.data);
            }
            if (!productRes.data || !newVarRes.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }

        openProductDetails(editMerchModal?.details?._id);
        setNewVariationOpen(false);
        setUploadedImg(undefined);
        setIsLoading(false);
    };

    const updateVariation = async (e: any) => {
        const name: string | null | undefined = e.target?.name;
        const id: string | undefined = name?.split("-")[2];

        const variation = editMerchModal?.variations.find(
            (variation) => variation._id === id
        );
        setIsLoading(true);

        try {
            mobileAxios(`merchandises/variation/${id}`, {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${userState?.admin_login_token_mobile}`,
                },
                data: {
                    adminId: userState?.admin_id_mobile,
                    size: name?.includes("size")
                        ? e.target.value
                        : variation.size,
                    color: name?.includes("color")
                        ? e.target.value
                        : variation.color,
                    price: name?.includes("price")
                        ? e.target.value
                        : variation.price,
                    stock: name?.includes("stock")
                        ? e.target.value
                        : variation.stock,
                },
            });
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }

        setIsLoading(false);
    };

    const deleteVariation = async (id: string) => {
        setIsLoading(true);

        try {
            await mobileAxios(`merchandises/variation/${id}`, {
                method: "DELETE",
                data: {
                    adminId: userState?.admin_id_mobile,
                },
                headers: {
                    Authorization: `Bearer ${userState?.admin_login_token_mobile}`,
                },
            });
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }

        openProductDetails(editMerchModal?.details?._id);
    };

    const handlePointsGenerate = async (e: FormEvent) => {
        e.preventDefault();
        setIsLoading(true);

        const formData = Object.values(e.target).reduce((obj, field) => {
            obj[field.name] = field.value;
            return obj;
        }, {});

        try {
            const res = await mobileAxios(`admin/refund`, {
                method: "POST",
                data: {
                    adminId: userState?.admin_id_mobile,
                    userId: addPointsModal,
                    points: parseInt(formData.points),
                },

                headers: {
                    Authorization: `Bearer ${userState?.admin_login_token_mobile}`,
                },
            });
            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            toast.success("Points added");
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }

        setIsLoading(false);
        setAddPointsModal(null);
    };

    const onSearchTextChange = (e: any) => {
        setInputSearchText(e.target.value);
    };

    return (
        <>
            {isLoading && <Loader withBg={true} />}

            <Modal
                isOpen={addPointsModal !== null}
                toggleModal={() => setAddPointsModal(null)}
            >
                <form className="mt-6" onSubmit={handlePointsGenerate}>
                    <h4 className="text-center text-lg font-[600]">
                        Generate points for user
                    </h4>

                    <div className="input-row mt-4">
                        <input
                            name="points"
                            type="number"
                            step={0.5}
                            placeholder="Total points to add"
                        />
                    </div>
                    <div className="flex justify-center space-x-4">
                        <Button
                            color="transparent"
                            className=" mt-4"
                            type="button"
                            onClick={() => setAddPointsModal(null)}
                        >
                            Cancel
                        </Button>
                        <Button className="ml-auto mt-4" type="submit">
                            Generate
                        </Button>
                    </div>
                </form>
            </Modal>

            <div className="h-fit flex gap-8 items-center justify-between mb-4 ">
                <h2 className="font-bold text-2xl">Users:</h2>
                <div className="input-row max-w-xs mb-0">
                    <input
                        type="text"
                        onChange={onSearchTextChange}
                        className="w-fit !py-2"
                        placeholder="Search "
                    />
                </div>
            </div>

            {rows.length > 0 && (
                <Box sx={{ width: "100%" }}>
                    <Paper sx={{ width: "100%", mb: 2 }}>
                        <TableContainer>
                            <Table
                                sx={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"
                                size={"medium"}
                            >
                                <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    rowCount={rows.length}
                                    onSelectAllClick={() => { }}
                                />
                                <TableBody>
                                    {rows
                                        .sort(getComparator(order, orderBy))
                                        .filter((item: any) => {
                                            return (
                                                item.email
                                                    ?.toLowerCase()
                                                    ?.includes(
                                                        inputSearchText
                                                    ) ||
                                                item.name
                                                    ?.toLowerCase()
                                                    ?.includes(inputSearchText)
                                                ||
                                                item.id
                                                    ?.toLowerCase()
                                                    ?.includes(inputSearchText)

                                            );
                                        })
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((row: any, index) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    tabIndex={-1}
                                                    key={row.id}
                                                >
                                                    <TableCell>
                                                        {row.id}
                                                    </TableCell>
                                                    <TableCell className="whitespace-nowrap">
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell className="whitespace-nowrap">
                                                        {row.email}
                                                    </TableCell>
                                                    <TableCell className="whitespace-nowrap">
                                                        {row.type}
                                                    </TableCell>

                                                    <TableCell className="whitespace-nowrap">
                                                        {row.level}
                                                    </TableCell>

                                                    <TableCell className="whitespace-nowrap">
                                                        <div className="flex gap-3 items-center">
                                                            <img
                                                                src={
                                                                    row.profile
                                                                }
                                                                alt=""
                                                                className="max-w-full h-16 w-16 object-contain bg-gray-100"
                                                            />
                                                            {row.title}
                                                        </div>
                                                    </TableCell>

                                                    <TableCell className="whitespace-nowrap">
                                                        {row.active}
                                                    </TableCell>
                                                    <TableCell className="whitespace-nowrap">
                                                        {dayjs(
                                                            row.created_date
                                                        ).format(
                                                            "ddd DD MMM YYYY hh:mm A"
                                                        )}
                                                    </TableCell>
                                                    <TableCell className="whitespace-nowrap">
                                                        <MUIButton
                                                            variant="outlined"
                                                            onClick={() => {
                                                                setAddPointsModal(
                                                                    row.id
                                                                );
                                                            }}
                                                        >
                                                            + Add points
                                                        </MUIButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                height: 53 * emptyRows,
                                            }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, 100]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Box>
            )}

            <Modal
                size="5xl"
                isOpen={editMerchModal !== null}
                toggleModal={() => {
                    setUploadedImg(undefined);
                    setEditMerchModal(null);
                }}
            >
                <form className="mt-6" onSubmit={handleEditSubmit}>
                    <div className="input-row">
                        <Dropzone
                            defaultValue={uploadedImg}
                            handleIconUpload={(src: string) =>
                                setUploadedImg(src)
                            }
                            previewClass="w-full"
                        />
                    </div>

                    <div className="input-row px-2">{""}</div>

                    <div className="input-row">
                        <input
                            name="title"
                            type="text"
                            placeholder="Title *"
                            defaultValue={editMerchModal?.details?.title}
                            required
                        />
                    </div>

                    <div className="input-row">
                        <textarea
                            name="description"
                            placeholder="Description *"
                            defaultValue={editMerchModal?.details?.description}
                            rows={4}
                            required
                        />
                    </div>

                    <div className="input-row">
                        <select
                            name="category"
                            id="category"
                            defaultValue={editMerchModal?.details?.category}
                            required
                        >
                            <option value={""} disabled>
                                Category:
                            </option>
                            {allCategories.map((category: any) => (
                                <option key={category._id} value={category._id}>
                                    {category.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <hr className="my-4" />

                    <div className="">
                        <h4 className="mb-4 font-semibold text-xl">
                            Variations:{" "}
                        </h4>
                        <ul className="list-style-disc pl-6">
                            {editMerchModal?.variations.map(
                                (variation: any, index: number) => (
                                    <>
                                        <li
                                            key={variation._id}
                                            className="flex items-center justify-between gap-4"
                                        >
                                            <div className="input-wrap space-x-4">
                                                <label
                                                    htmlFor={`color-${index}`}
                                                >
                                                    Color:
                                                </label>
                                                <input
                                                    className="flex-1 !w-28 inline-block"
                                                    name={`variation-color-${variation._id}`}
                                                    type="text"
                                                    placeholder="Color *"
                                                    onBlur={updateVariation}
                                                    defaultValue={
                                                        variation.color
                                                    }
                                                    required
                                                />
                                            </div>
                                            <div className="input-wrap !w-fit space-x-4">
                                                <label
                                                    htmlFor={`size-${index}`}
                                                >
                                                    Size:
                                                </label>
                                                <input
                                                    className="flex-1 !w-16 inline-block text-center"
                                                    name={`variation-size-${variation._id}`}
                                                    type="text"
                                                    onBlur={updateVariation}
                                                    placeholder="Size *"
                                                    defaultValue={
                                                        variation?.size
                                                    }
                                                    required
                                                />
                                            </div>

                                            <div className="input-wrap space-x-4">
                                                <label
                                                    htmlFor={`color-${index}`}
                                                >
                                                    Price:
                                                </label>

                                                <div className="input-wrap w-28 inline-flex items-center gap-2">
                                                    <span className="text-xl">
                                                        $
                                                    </span>
                                                    <input
                                                        className="flex-1 text-center"
                                                        name={`variation-price-${variation._id}`}
                                                        onBlur={updateVariation}
                                                        type="number"
                                                        step="any"
                                                        placeholder="Price *"
                                                        defaultValue={
                                                            variation?.price
                                                        }
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="input-wrap space-x-4">
                                                <label
                                                    htmlFor={`size-${index}`}
                                                >
                                                    In Stock:
                                                </label>

                                                <input
                                                    className="flex-1 !w-28 inline-block text-center"
                                                    name={`variation-stock-${variation._id}`}
                                                    type="text"
                                                    onBlur={updateVariation}
                                                    placeholder="Instock *"
                                                    defaultValue={
                                                        variation?.stock
                                                    }
                                                    required
                                                />
                                            </div>

                                            <div>
                                                <button
                                                    type="button"
                                                    onClick={() =>
                                                        deleteVariation(
                                                            variation._id
                                                        )
                                                    }
                                                    className="block mt-2 text-red-500"
                                                >
                                                    <AiFillDelete />
                                                </button>
                                            </div>
                                        </li>
                                        <br />
                                    </>
                                )
                            )}

                            {newVariationOpen === true && (
                                <li className="flex justify-between gap-4 mb-6">
                                    <div className="input-wrap space-x-4">
                                        <label htmlFor={`color`}>Color:</label>
                                        <input
                                            className="flex-1 !w-28 inline-block"
                                            name={`variation-color-new`}
                                            type="text"
                                            placeholder="Color *"
                                            required
                                        />
                                    </div>
                                    <div className="input-wrap !w-fit space-x-4">
                                        <label htmlFor={`size`}>Size:</label>
                                        <input
                                            className="flex-1 !w-16 inline-block text-center"
                                            name={`variation-size-new`}
                                            type="text"
                                            placeholder="Size *"
                                            required
                                        />
                                    </div>

                                    <div className="input-wrap space-x-4">
                                        <label htmlFor={`color`}>Price:</label>

                                        <div className="input-wrap w-28 inline-flex items-center gap-2">
                                            <span className="text-xl">$</span>
                                            <input
                                                className="flex-1 text-center"
                                                name={`variation-price-new`}
                                                type="number"
                                                step="any"
                                                placeholder="Price *"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="input-wrap space-x-4">
                                        <label htmlFor={`size`}>
                                            In Stock:
                                        </label>

                                        <input
                                            className="flex-1 !w-28 inline-block text-center"
                                            name={`variation-stock-new`}
                                            type="text"
                                            placeholder="Instock *"
                                            required
                                        />
                                    </div>
                                </li>
                            )}
                        </ul>

                        <div className="h-fit">
                            <button
                                type={newVariationOpen ? "submit" : "button"}
                                onClick={() =>
                                    !newVariationOpen &&
                                    setNewVariationOpen((p) => !p)
                                }
                                className="h-full min-h-[50px] w-full flex items-center justify-center border"
                            >
                                + Add Variation
                            </button>
                        </div>
                    </div>

                    <div className="flex space-x-4">
                        <Button
                            color="transparent"
                            className="ml-auto mt-8"
                            type="button"
                            onClick={() => setEditMerchModal(null)}
                        >
                            Cancel
                        </Button>
                        <Button className="ml-auto mt-8" type="submit">
                            Save
                        </Button>
                    </div>
                </form>
            </Modal>

            <Modal
                size="3xl"
                isOpen={addMerchModal}
                toggleModal={toggleAddMerchModal}
            >
                <form className="mt-6" onSubmit={handleSubmit}>
                    <div className="input-row">
                        <Dropzone
                            defaultValue={uploadedImg}
                            handleIconUpload={(src: string) =>
                                setUploadedImg(src)
                            }
                            previewClass="w-full"
                        />
                    </div>

                    <div className="input-row">
                        <input
                            name="title"
                            type="text"
                            placeholder="Title *"
                            required
                        />
                    </div>

                    <div className="input-row">
                        <textarea
                            name="description"
                            placeholder="Description *"
                            rows={4}
                            required
                        />
                    </div>

                    <div className="input-row">
                        <select
                            name="category"
                            id="category"
                            defaultValue={""}
                            required
                        >
                            <option value={""} disabled>
                                Category:
                            </option>
                            {allCategories.map((category: any) => (
                                <option key={category._id} value={category._id}>
                                    {category.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="flex space-x-4">
                        <Button
                            color="transparent"
                            className="ml-auto mt-8"
                            type="button"
                            onClick={toggleAddMerchModal}
                        >
                            Cancel
                        </Button>
                        <Button className="ml-auto mt-8" type="submit">
                            Save
                        </Button>
                    </div>
                </form>
            </Modal>

            <Modal
                isOpen={deleteModal !== null}
                toggleModal={() => setDeleteModal(null)}
            >
                <form className="mt-6" onSubmit={handleMerchDelete}>
                    <h4 className="text-center text-lg font-[600]">
                        Are you sure you want to delete this room?
                    </h4>
                    <div className="flex justify-center space-x-4">
                        <Button
                            className=" mt-8"
                            type="button"
                            onClick={() => setDeleteModal(null)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className=" mt-8 !bg-red-600 !border-red-600"
                            type="submit"
                        >
                            Delete
                        </Button>
                    </div>
                </form>
            </Modal>
        </>
    );
};

export default AdminUsersByCountryId;
