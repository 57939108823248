import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import Button from '../../Components/UI/Button'
import { useUser } from '../../context/UserContext'
import { mobileAxios } from '../../utils/mobileAxios'

type Props = {}

const Notifications = (props: Props) => {
    const [isLoading, setIsLoading] = useState(false)

    const { userState } = useUser();
    const params = useParams();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);

        const data = Object.values(e.target).reduce((obj, field) => {
            obj[field.name] = field.value;
            return obj;
        }, {});

        try {
            await mobileAxios("notification", {
                method: "POST",
                data: {
                    adminId: userState?.admin_id_mobile,
                    country: params.country_id,
                    title: data.title,
                    content: data.content,
                },
                headers: {
                    Authorization: `Bearer ${userState?.admin_login_token_mobile}`,
                },
            })

            setIsLoading(false);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }


    };

    return (
        <div>
            <h2 className="font-bold text-2xl">Send Notification :</h2>
            <br />
            <form onSubmit={handleSubmit} action="">
                <div className="input-wrap space-x-4">
                    <label>Title:</label>
                    <input
                        className="flex-1 mt-2 h-12 !ml-0"
                        name={`title`}
                        type="text"
                        placeholder="Title *"
                        required
                    />
                </div>
                <br />
                <div className="input-wrap space-x-4">
                    <label>Content:</label>
                    <textarea
                        className="flex-1 mt-2 !ml-0"
                        name={`content`}
                        placeholder="Content *"
                        required
                    />
                </div>

                <Button className="ml-auto mt-8" type="submit" disabled={isLoading}>
                    Send
                </Button>
            </form>
        </div>
    )
}

export default Notifications