import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Axios } from "../utils/axios";
import { useUser } from "../context/UserContext";

import Button from "../Components/UI/Button";
import { mobileAxios } from "../utils/mobileAxios";

type Props = {};

const Login = (props: Props) => {
    const navigate = useNavigate();
    const { setUser } = useUser();
    const [isLoading, setisLoading] = useState(false)

    const handleLoginSubmit = async (e: React.FormEvent) => {
        setisLoading(true);
        e.preventDefault();

        const data = Object.values(e.target).reduce((obj, field) => {
            obj[field.name] = field.value;
            return obj;
        }, {});

        //uYo1&Nm5228v
        try {
            const res = await Axios.post("check_admin_login", {
                username: data.username,
                password: data.password,
            });
            const mobileRes = await mobileAxios.post("admin/login ", {
                username: data.username,
                password: data.password,
            });

            if (!res.data) {
                throw new Error("not found");
            }
            const admin_id = res.data[0].admin_id;
            const admin_login_token = res.data[0].admin_login_token;
            const admin_username = res.data[0].admin_username;
            const admin_login_token_mobile = mobileRes.data.login_token;
            const admin_id_mobile = mobileRes.data._id;
            setisLoading(false);

            setUser({
                admin_id: admin_id,
                admin_login_token: admin_login_token,
                admin_username: admin_username,
                admin_login_token_mobile: admin_login_token_mobile,
                admin_id_mobile: admin_id_mobile,
            });

            navigate("/pages/home");
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div className="min-h-screen bg-darkblue flex px-8">
            <div className="w-full max-w-4xl bg-white m-auto rounded-md py-8 px-4">
                <div className="mx-auto w-fit">
                    <img src="/images/logo.png" alt="" />
                </div>
                <form className="mt-6" onSubmit={handleLoginSubmit}>
                    <div className="input-row">
                        <input
                            name="username"
                            type="text"
                            placeholder="Username *"
                            required
                        />
                    </div>

                    <div className="input-row">
                        <input
                            name="password"
                            type="password"
                            placeholder="Password *"
                            required
                        />
                    </div>

                    <Button disabled={isLoading} className={`ml-auto mt-8 disabled:cursor-wait disabled:opacity-40`} type="submit">
                        Submit
                    </Button>
                </form>
            </div>
        </div>
    );
};

export default Login;
