import { useUser } from "../../context/UserContext";
import { NavLink, useLocation } from "react-router-dom";

import { AiOutlineMenu } from "react-icons/ai";

type Props = {
    toggleSideNav: () => void;
};

const Header = ({ toggleSideNav }: Props) => {
    const { userState, setUser } = useUser();

    const routerLocation = useLocation();

    return (
        <div>
            <header className=" py-4 shadow">
                <div className="px-4 flex items-center justify-between">
                    {/* left toggle */}
                    <div className="flex items-center">
                        <button onClick={() => toggleSideNav()}>
                            <AiOutlineMenu />
                        </button>
                        <NavLink
                            className="mx-4"
                            to={
                                routerLocation.pathname.includes("mobile-app")
                                    ? "/dashboard"
                                    : "/mobile-app"
                            }
                        >
                            Switch to{" "}
                            {routerLocation.pathname.includes("mobile-app")
                                ? "Website"
                                : "Mobile app"}{" "}
                            admin
                        </NavLink>
                    </div>
                    {/* logo */}
                    <NavLink
                        className="h-16 w-52"
                        to={
                            routerLocation.pathname.includes("mobile-app")
                                ? "/mobile-app"
                                : "/dashboard"
                        }
                    >
                        <img
                            key={1}
                            src="/images/logo.png"
                            height={64}
                            width={208}
                            alt=""
                        />
                    </NavLink>
                    {/* user */}
                    <button className="relative group min-w-[100px] text-left">
                        <div>{userState?.admin_username}</div>
                        <div
                            onClick={() => setUser(null)}
                            className="bg-white py-2 px-4 mt-2 border rounded-sm shadow-lg absolute top-full -left-4 right-0 opacity-0 invisible group-focus:opacity-100 group-focus:visible"
                        >
                            Logout
                        </div>
                    </button>
                </div>
            </header>
        </div>
    );
};

export default Header;
