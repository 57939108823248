import React from "react";

import "./Loader.css";

type Props = {
    withBg?: boolean;
};

const Loader = ({ withBg }: Props) => {
    return (
        <div className={`loaderWrapper ${withBg ? "withBg" : ""}`}>
            <div className="loader"></div>
        </div>
    );
};

export default Loader;
