import React, { useState } from "react";
import EditWrapper from "../EditWrapper";
import Button from "../UI/Button";
import Modal from "../UI/Modal";

type Props = {
    button: {
        text: string;
        href: string;
    };
    component_id: string | number;
    sectionKey: string;
    onSubmit: (
        submittedData: {
            component_title?: string;
            component_description?: string;
        },
        sectionKey: string,
        updating_component_id: string | number
    ) => void;
};

const ButtonEdit = ({ component_id, onSubmit, sectionKey, button }: Props) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const toggleModal = () => {
        setModalOpen((p) => !p);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        const data = Object.values(e.target).reduce((obj, field) => {
            obj[field.name] = field.value;
            return obj;
        }, {});

        onSubmit(
            {
                component_title: data.text,
                component_description: data.link,
            },
            sectionKey,
            component_id
        );
        toggleModal();
    };

    return (
        <>
            <Modal size="3xl" isOpen={modalOpen} toggleModal={toggleModal}>
                <form className="mt-6" onSubmit={handleSubmit}>
                    <div className="input-row">
                        <input
                            name="text"
                            type="text"
                            placeholder="Text *"
                            defaultValue={button.text}
                            required
                        />
                    </div>

                    <div className="input-row">
                        <input
                            name="link"
                            type="text"
                            placeholder="Link *"
                            defaultValue={button.href}
                            required
                        />
                    </div>

                    <div className="flex space-x-4">
                        <Button
                            color="transparent"
                            className="ml-auto mt-8"
                            type="button"
                            onClick={() => toggleModal()}
                        >
                            Cancel
                        </Button>
                        <Button className="ml-auto mt-8" type="submit">
                            Save
                        </Button>
                    </div>
                </form>
            </Modal>
            <EditWrapper toggleModal={toggleModal}>
                <div
                    className={`my-2 block w-fit custom-btn ${"bg-darkblue text-white border-darkblue"}`}
                >
                    {button.text}
                </div>
            </EditWrapper>
        </>
    );
};

export default ButtonEdit;
