
import { useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";

import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Dropzone from "../../../Components/Dropzone";
import Loader from "../../../Components/Loader/Loader";
import Button from "../../../Components/UI/Button";
import Modal from "../../../Components/UI/Modal";
import { useUser } from "../../../context/UserContext";
import { MEDIA_URL, toast_error_settings } from "../../../utils/constants";
import { mobileAxios } from "../../../utils/mobileAxios";

import { Button as MUIButton } from "@mui/material";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import dayjs from "dayjs";

type Props = {};

function descendingComparator(a: any, b: any, orderBy: any) {
    if (orderBy === "total_price") {
        if (parseFloat(b[orderBy]) < parseFloat(a[orderBy])) {
            return -1;
        }
        if (parseFloat(b[orderBy]) > parseFloat(a[orderBy])) {
            return 1;
        }
        return 0;
    } else if (orderBy === "id" || orderBy === "statys") {
        return b[orderBy].localeCompare(a[orderBy]);
    } else if (orderBy === "created_date") {
        return dayjs(b[orderBy]).isAfter(dayjs(a[orderBy])) ? -1 : 1;
    } else {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
}

function getComparator(order: any, orderBy: any) {
    return order === "desc"
        ? (a: any, b: any) => descendingComparator(a, b, orderBy)
        : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

const headCells = [
    {
        id: "description",
        label: "Description",
    },
    {
        id: "created_date",
        label: "Created Date",
    },
    {
        id: "action",
        label: "",
    },
];

function EnhancedTableHead(props: any) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: any) => (event: any) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        padding={"normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                            className="font-semibold text-base"
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const AdminNftBenefits = (props: Props) => {
    const [data, setData] = useState<any>("loading");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [uploadedImg, setUploadedImg] = useState<string | undefined>(
        undefined
    );

    const [rows, setRows] = useState([]);

    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("created_date");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleRequestSort = (event: any, property: any) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const [allCategories, setAllCategories] = useState([]);

    const [editMerchModal, setEditMerchModal] = useState<{
        details: any;
        variations: any[];
    } | null>(null);

    const [newVariationOpen, setNewVariationOpen] = useState(false);

    const [addMerchModal, setAddMerchModal] = useState<boolean>(false);
    const [deleteModal, setDeleteModal] = useState<string | null>(null);

    const toggleAddMerchModal = () => {
        setAddMerchModal((p) => !p);
    };

    const { userState } = useUser();

    const params = useParams();

    const fetchData = async () => {
        if (!isLoading) {
            setIsLoading(true);
        }

        mobileAxios
            .get(`nft/benefits`, {
                headers: {
                    Authorization: `Bearer ${userState?.admin_login_token_mobile}`,
                }
            })

            .then((res) => {
                const tmp_data = res.data?.sort(
                    (a: any, b: any) =>
                        new Date(b.created_date).getTime() -
                        new Date(a.created_date).getTime()
                );

                const rowsData = res.data?.map((item: any) => ({
                    id: item._id,
                    created_date: item.created_date,
                    description: item.description,
                }));
                // item.status

                setRows(rowsData);

                setData(tmp_data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setData(err.message);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchData();


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userState]);

    if (data === "loading") {
        return <Loader />;
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);

        const formData = Object.values(e.target).reduce((obj, field) => {
            obj[field.name] = field.value;
            return obj;
        }, {});

        try {
            const res = await mobileAxios("nft/benefits", {
                method: "POST",
                data: {
                    adminId: userState?.admin_id_mobile,
                    country: params.country_id,
                    description: formData.description,
                },
                headers: {
                    Authorization: `Bearer ${userState?.admin_login_token_mobile}`,
                },
            });
            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }

        setIsLoading(false);
        toggleAddMerchModal();
    };

    const handleMerchDelete = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const res = await mobileAxios(`nft/benefits`, {
                method: "DELETE",
                data: {
                    adminId: userState?.admin_id_mobile,
                    benefitId: deleteModal
                },
                headers: {
                    Authorization: `Bearer ${userState?.admin_login_token_mobile}`,
                },
            });
            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }

        setIsLoading(false);
        setDeleteModal(null);
    };

    const openProductDetails = async (detailId: string) => {
        setIsLoading(true);

        const foundRow: any = rows.find((item) => (item as any).id?.toString() === detailId?.toString())

        setEditMerchModal({
            details: { id: foundRow?.id, description: foundRow?.description },
            variations: [],
        });

        setIsLoading(false);
    };

    const handleEditSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);

        const formData = Object.values(e.target).reduce((obj, field) => {
            obj[field.name] = field.value;
            return obj;
        }, {});

        setIsLoading(true);

        try {
            // update product details
            const productRes = await mobileAxios(
                `nft/benefits`,
                {
                    method: "PATCH",
                    data: {
                        adminId: userState?.admin_id_mobile,
                        benefitId: editMerchModal?.details?.id,
                        description: formData.description,
                    },

                    headers: {
                        Authorization: `Bearer ${userState?.admin_login_token_mobile}`,
                    },
                }
            );

            const newVarData: any = {};
            Object.keys(formData).forEach((k) => {
                if (k.includes("new")) {
                    newVarData[k?.split("-new")[0]?.split("variation-")[1]] =
                        formData[k];
                }
            });

            if (
                productRes.data === "token error"
            ) {
                throw new Error(productRes.data);
            }
            if (!productRes.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }

        setNewVariationOpen(false);
        setUploadedImg(undefined);
        setIsLoading(false);
    };



    return (
        <>
            {isLoading && <Loader withBg={true} />}

            <div className="h-fit mb-8 flex gap-8 items-center">
                <h2 className="font-bold text-2xl">NFT Benefits:</h2>

                <button
                    onClick={toggleAddMerchModal}
                    className="px-8 py-3 flex items-center justify-center border"
                >
                    + Add Benefit
                </button>
            </div>

            {rows.length > 0 && (
                <Box sx={{ width: "100%" }}>
                    <Paper sx={{ width: "100%", mb: 2 }}>
                        <TableContainer>
                            <Table
                                sx={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"
                                size={"medium"}
                            >
                                <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    rowCount={rows.length}
                                    onSelectAllClick={() => { }}
                                />
                                <TableBody>
                                    {rows
                                        .sort(getComparator(order, orderBy))
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((row: any, index) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    tabIndex={-1}
                                                    key={row.id}
                                                >
                                                    {/* <TableCell>
                                                        {row.id}
                                                    </TableCell> */}
                                                    <TableCell>
                                                        <p>
                                                            {row.description
                                                                ?.length > 50
                                                                ? row.description?.substring(
                                                                    0,
                                                                    50
                                                                ) + "..."
                                                                : row.description}
                                                        </p>
                                                    </TableCell>
                                                    <TableCell>
                                                        {dayjs(
                                                            row.created_date
                                                        ).format(
                                                            "ddd DD MMM YYYY hh:mm A"
                                                        )}
                                                    </TableCell>

                                                    <TableCell>
                                                        <MUIButton
                                                            variant="outlined"
                                                            onClick={() => {
                                                                openProductDetails(
                                                                    row.id
                                                                );
                                                            }}
                                                        >
                                                            EDIT ITEM
                                                        </MUIButton>
                                                        <br />

                                                        <MUIButton
                                                            className="!block !mt-2"
                                                            variant="outlined"
                                                            color="error"
                                                            onClick={() =>
                                                                setDeleteModal(
                                                                    row.id
                                                                )
                                                            }
                                                        >
                                                            DELETE ITEM
                                                        </MUIButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                height: 53 * emptyRows,
                                            }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Box>
            )}

            <Modal
                size="5xl"
                isOpen={editMerchModal !== null}
                toggleModal={() => {
                    setUploadedImg(undefined);
                    setEditMerchModal(null);
                }}
            >
                <form className="mt-6" onSubmit={handleEditSubmit}>
                    <div className="input-row">
                        <textarea
                            name="description"
                            placeholder="Description *"
                            defaultValue={editMerchModal?.details?.description}
                            rows={4}
                            required
                        />
                    </div>


                    <hr className="my-4" />

                    <div className="flex space-x-4">
                        <Button
                            color="transparent"
                            className="ml-auto mt-8"
                            type="button"
                            onClick={() => setEditMerchModal(null)}
                        >
                            Cancel
                        </Button>
                        <Button className="ml-auto mt-8" type="submit">
                            Save
                        </Button>
                    </div>
                </form>
            </Modal>

            <Modal
                size="3xl"
                isOpen={addMerchModal}
                toggleModal={toggleAddMerchModal}
            >
                <form className="mt-6" onSubmit={handleSubmit}>
                    <div className="input-row">
                        <textarea
                            name="description"
                            placeholder="Description *"
                            rows={4}
                            required
                        />
                    </div>

                    <div className="flex space-x-4">
                        <Button
                            color="transparent"
                            className="ml-auto mt-8"
                            type="button"
                            onClick={toggleAddMerchModal}
                        >
                            Cancel
                        </Button>
                        <Button className="ml-auto mt-8" type="submit">
                            Save
                        </Button>
                    </div>
                </form>
            </Modal>

            <Modal
                isOpen={deleteModal !== null}
                toggleModal={() => setDeleteModal(null)}
            >
                <form className="mt-6" onSubmit={handleMerchDelete}>
                    <h4 className="text-center text-lg font-[600]">
                        Are you sure you want to delete this room?
                    </h4>
                    <div className="flex justify-center space-x-4">
                        <Button
                            className=" mt-8"
                            type="button"
                            onClick={() => setDeleteModal(null)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className=" mt-8 !bg-red-600 !border-red-600"
                            type="submit"
                        >
                            Delete
                        </Button>
                    </div>
                </form>
            </Modal>
        </>
    );
};

export default AdminNftBenefits;
