import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { mobileAxios } from "../../utils/mobileAxios";
import { useUser } from "../../context/UserContext";
import Loader from "../../Components/Loader/Loader";

import dayjs from "dayjs";

import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { Button } from "@mui/material";

function createData(id, created_date, total_price, userId, status) {
    return {
        id,
        created_date,
        total_price,
        userId,
        status,
    };
}

function descendingComparator(a, b, orderBy) {
    if (orderBy === "total_price") {
        if (parseFloat(b[orderBy]) < parseFloat(a[orderBy])) {
            return -1;
        }
        if (parseFloat(b[orderBy]) > parseFloat(a[orderBy])) {
            return 1;
        }
        return 0;
    } else if (orderBy === "id" || orderBy === "statys") {
        return b[orderBy].localeCompare(a[orderBy]);
    } else if (orderBy === "created_date") {
        return dayjs(b[orderBy]).isAfter(dayjs(a[orderBy])) ? -1 : 1;
    } else {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const headCells = [
    {
        id: "id",
        label: "ID",
    },
    {
        id: "created_date",
        label: "Order Date",
    },
    {
        id: "total_price",
        label: "Total",
    },
    {
        id: "userId",
        label: "User",
    },
    {
        id: "status",
        label: "Status",
    },
    {
        id: "action",
        label: "Action",
    },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        padding={"normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                            className="font-semibold text-base"
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = () => {
    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
            }}
        >
            <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                Orders
            </Typography>

            {/* <Tooltip title="Filter list">
                <IconButton>
                    <Filter1 />
                </IconButton>
            </Tooltip> */}
        </Toolbar>
    );
};

const AdminOrders = (prkops) => {
    const [isLoading, setIsLoading] = useState(true);
    const [, setData] = useState("loading");
    const [rows, setRows] = useState([]);

    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("created_date");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const navigate = useNavigate();

    const handleClick = (event, id) => {
        navigate(`/mobile-app/orders/${id}`);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const fetchData = async () => {
        setIsLoading(true);

        mobileAxios
            .get(`orders`, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    Authorization: `Bearer ${userState?.admin_login_token_mobile}`,
                },
            })
            .then((res) => {
                const rowsData = res.data?.map((item) =>
                    createData(
                        item._id,
                        item.created_date,
                        item.total_price,
                        item.userId,
                        item.status
                    )
                );

                setRows(rowsData);

                setData(res.data);
                setIsLoading(false);
                return;
            })
            .catch((err) => {
                console.error(err);
                if (err.message === "Network Error") {
                    fetchData();
                }

                setData(err.message);
                // setIsLoading(false);
                return;
            });
    };

    const { userState } = useUser();

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userState]);

    return (
        <>
            {isLoading && <Loader withBg={true} />}
            {rows.length > 0 && (
                <Box sx={{ width: "100%" }}>
                    <Paper sx={{ width: "100%", mb: 2 }}>
                        <EnhancedTableToolbar />
                        <TableContainer>
                            <Table
                                sx={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"
                                size={"medium"}
                            >
                                <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    rowCount={rows.length}
                                />
                                <TableBody>
                                    {rows
                                        .sort(getComparator(order, orderBy))
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((row, index) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    onClick={(event) =>
                                                        handleClick(
                                                            event,
                                                            row.id
                                                        )
                                                    }
                                                    tabIndex={-1}
                                                    key={row.id}
                                                >
                                                    <TableCell>
                                                        {row.id}
                                                    </TableCell>
                                                    <TableCell>
                                                        {dayjs(
                                                            row.created_date
                                                        ).format(
                                                            "ddd DD MMM YYYY hh:mm A"
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.total_price}
                                                    </TableCell>
                                                    <TableCell>
                                                        <>
                                                            <p>
                                                                {
                                                                    row.userId
                                                                        ?.name
                                                                }
                                                            </p>
                                                            <a
                                                                href={`mailto:${row.userId?.email}`}
                                                            >
                                                                {
                                                                    row.userId
                                                                        ?.email
                                                                }
                                                            </a>
                                                        </>
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.status}
                                                    </TableCell>

                                                    <TableCell>
                                                        <Button
                                                            onClick={() =>
                                                                navigate(
                                                                    `/mobile-app/orders/${row.id}`
                                                                )
                                                            }
                                                        >
                                                            VIEW ORDER
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                height: 53 * emptyRows,
                                            }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Box>
            )}
            {/* <MaterialTable
                title="Basic Search Preview"
                columns={[
                    { title: "Id", field: "id" },
                    { title: "Order Date", field: "date", type: "datetime" },
                    { title: "Total", field: "total", type: "currency" },
                    { title: "User", field: "user" },
                    { title: "Status", field: "status" },
                    {
                        title: "", field: "view_btn", render(data, type) {
                            <button>VIEW ORDER</button>
                        },
                    },
                ]}
                data={data?.map((item: any) => ({
                    id: item._id,
                    date: item.created_date,
                    total: `$ ${item.total_price}`,
                    user: item.userId,
                    status: item.status,
                }))}
            actions={[
                {
                    icon: '',
                    tooltip: 'View order',
                    onClick: (event, rowData) => { }
                },
            ]}
            options={{
                search: true,
                actionsColumnIndex: -1,
                filtering: true
            }}
            /> */}
        </>
    );
};

export default AdminOrders;
