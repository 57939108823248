import { useState, useEffect } from "react";
import { useUser } from "../../context/UserContext";

import { Axios } from "../../utils/axios";
import { findBySlug } from "../../utils/functions";

import ImageEdit from "../../Components/EditComponents/Image";
import TitleWithText from "../../Components/EditComponents/TitleWithText";
import AddFaqEvent from "../../Components/AddFaqEvent";
import FaqRow from "../../Components/EditComponents/FaqRow";
import Loader from "../../Components/Loader/Loader";
import StoryEdit from "../../Components/StoryEdit";
import AddStory from "../../Components/AddStory";
import { toast } from "react-toastify";
import { toast_error_settings } from "../../utils/constants";

type Props = {};

const AdminCommunity = (props: Props) => {
    const [data, setData] = useState<any>("loading");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { userState, setUser } = useUser();

    const fetchData = async () => {
        if (!isLoading) {
            setIsLoading(true);
        }

        Axios.post("build_pages", {
            adminid: userState?.admin_id,
            token: userState?.admin_login_token,
            page_id: 3,
        })
            .then((res) => {
                if (res.data.length === 0) {
                    throw new Error("Nothing returned");
                }
                setData(res.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setData(err.message);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userState]);

    if (data === "loading") {
        return <Loader />;
    }

    const handleSubmit = async (
        submittedData: {
            component_title?: string;
            component_description?: string;
        },
        sectionKey: string,
        updating_component_id: string | number
    ) => {
        setIsLoading(true);
        const updated_components = data[sectionKey].map((item: any) => {
            if (
                item.component_id.toString() ===
                updating_component_id.toString()
            ) {
                return {
                    ...item,
                    component_title: submittedData.component_title,
                    component_description:
                        submittedData.component_description || "",
                };
            } else {
                return {
                    ...item,
                    component_title: item.title,
                    component_description: item.description || "",
                };
            }
        });

        try {
            const res = await Axios.post("update_component", {
                adminid: userState?.admin_id,
                token: userState?.admin_login_token,
                components: updated_components,
            });
            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }
    };

    const faq: { type: string; data: any[] }[] = [];
    data["faq"].forEach((item: any) => {
        const inFaq = faq.find(
            (faq_item) =>
                faq_item.type.toLowerCase() === item.type.toLowerCase()
        );
        if (!inFaq) {
            faq.push({
                type: item.type.toString().toLowerCase(),
                data: [item],
            });
        } else {
            inFaq.data.push(item);
        }
    });

    return (
        <>
            {isLoading && <Loader withBg={true} />}
            {/* hero */}
            <div className="w-full flex">
                <div className="flex-1">
                    <TitleWithText
                        component_id={
                            findBySlug(data["hero"], "text").component_id
                        }
                        title={findBySlug(data["hero"], "text").title}
                        text={findBySlug(data["hero"], "text").description}
                        onSubmit={handleSubmit}
                        sectionKey="hero"
                    />
                </div>
                <div className="flex-[0.4]">
                    <ImageEdit
                        component_id={
                            findBySlug(data["hero"], "image").component_id
                        }
                        sectionKey="hero"
                        src={findBySlug(data["hero"], "image").title}
                        onSubmit={handleSubmit}
                    />
                </div>
            </div>

            <hr className="my-10" />

            {/* community */}
            <div className="flex gap-4">
                <div className="flex-1">
                    <TitleWithText
                        component_id={
                            findBySlug(data["community"], "text").component_id
                        }
                        title={findBySlug(data["community"], "text").title}
                        text={findBySlug(data["community"], "text").description}
                        onSubmit={handleSubmit}
                        sectionKey="community"
                    />
                </div>
                <div className="flex-[0.5]">
                    <ImageEdit
                        component_id={
                            findBySlug(data["community"], "image").component_id
                        }
                        src={findBySlug(data["community"], "image").title}
                        sectionKey="community"
                        onSubmit={handleSubmit}
                    />
                </div>
            </div>

            <hr className="my-10" />

            {/* stories */}
            <div className="gap-2">
                <TitleWithText
                    component_id={data["stories-intro"][0].component_id}
                    title={data["stories-intro"][0].title}
                    text={data["stories-intro"][0].description}
                    onSubmit={handleSubmit}
                    sectionKey="stories-intro"
                />
                <div className="my-2"></div>

                <div>
                    {data["stories"].map((story: any) => (
                        <>
                            <StoryEdit
                                setIsLoading={setIsLoading}
                                key={story.component_id}
                                component_id={story.story_id}
                                title={story.title}
                                text={story.description}
                                sectionKey="stories"
                                image={story.image}
                                fetchData={fetchData}
                                storyItem={story}
                            />
                            <div className="my-4"></div>
                        </>
                    ))}
                    <div>
                        <AddStory
                            fetchData={fetchData}
                            setLoading={() => setIsLoading(true)}
                        />
                    </div>
                </div>
            </div>

            <hr className="my-10" />

            {/* events */}
            <div className="flex gap-4">
                <div className="flex-1">
                    <TitleWithText
                        component_id={
                            findBySlug(data["events"], "title").component_id
                        }
                        title={findBySlug(data["events"], "title").title}
                        text={findBySlug(data["events"], "title").description}
                        onSubmit={handleSubmit}
                        sectionKey="events"
                    />
                </div>
                <div className="flex-[0.5]">
                    <ImageEdit
                        component_id={
                            findBySlug(data["events"], "image").component_id
                        }
                        src={findBySlug(data["events"], "image").title}
                        sectionKey="events"
                        onSubmit={handleSubmit}
                    />
                </div>
            </div>

            <hr className="my-10" />

            {/* faq */}
            <div className="flex gap-4">
                <div className="flex-1">
                    <div>
                        {faq.map((type) => (
                            <div key={type.type} className="my-2">
                                <h3 className="text-xl font-extrabold mb-1 tracking-wide uppercase">
                                    {type.type}
                                </h3>
                                {type.data.map((faq, index: number) => (
                                    <>
                                        <FaqRow
                                            key={faq.faq_id + index}
                                            component_id={faq.faq_id}
                                            title={faq.title}
                                            type={faq.type}
                                            description={faq.description}
                                            handleSubmit={handleSubmit}
                                            fetchData={fetchData}
                                            setLoading={() =>
                                                setIsLoading(true)
                                            }
                                        />
                                        <div className="mt-2"></div>
                                    </>
                                ))}
                            </div>
                        ))}
                        <AddFaqEvent
                            fetchData={fetchData}
                            setLoading={() => setIsLoading(true)}
                        />
                    </div>
                </div>
                <div className="flex-[0.5]">
                    <ImageEdit
                        component_id={data["faq-image"][0].component_id}
                        src={data["faq-image"][0].title}
                        sectionKey="faq-image"
                        onSubmit={handleSubmit}
                    />
                </div>
            </div>
        </>
    );
};

export default AdminCommunity;
