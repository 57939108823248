import React from "react";

type Props = {
    children: React.ReactNode;
    href?: string;
    onClick?: () => void;
    color?: "darkblue" | "green" | "transparent";
    className?: string;
    type?: "button" | "submit";
    disabled?: boolean;
};

const Button = ({
    children,
    color = "darkblue",
    href,
    onClick,
    className,
    type = "button",
    disabled = false,
}: Props) => {
    return (
        <>
            {href ? (
                <a
                    href={href}
                    className={`${className} block w-fit custom-btn ${color === "darkblue"
                        ? "bg-darkblue text-white border-darkblue"
                        : color === "green"
                            ? "bg-lightgreen text-white bordbg-lightgreen"
                            : ""
                        }`}
                >
                    {children}
                </a>
            ) : (
                <button
                    type={type}
                    disabled={disabled}
                    onClick={() => onClick && onClick()}
                    className={`${className} block w-fit custom-btn disabled:opacity-40 disabled:cursor-wait ${color === "darkblue"
                        ? "bg-darkblue text-white border-darkblue"
                        : color === "green"
                            ? "bg-lightgreen text-white bordbg-lightgreen"
                            : ""
                        }`}
                >
                    {children}
                </button>
            )}
        </>
    );
};

export default Button;
