import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Dropzone from "../../Components/Dropzone";
import EditWrapper from "../../Components/EditWrapper";
import Loader from "../../Components/Loader/Loader";
import Button from "../../Components/UI/Button";
import Modal from "../../Components/UI/Modal";
import { useUser } from "../../context/UserContext";
import { MEDIA_URL, toast_error_settings } from "../../utils/constants";
import { mobileAxios } from "../../utils/mobileAxios";
import { Axiosget } from "../../utils/axiosget";
import Select from "react-select";
import axios from "axios";
import { json } from "stream/consumers";

type Props = {};

const MobileAnnouncements = (props: Props) => {
    const [data, setData] = useState<any>("loading");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [uploadedImage, setUploadedImage] = useState("");
    const [redirectionType, setredirectionType] = useState<
        "internal" | "external" | null
    >(null);
    const [internalType, setInternalType] = useState<
        null | "merch" | "menu" | "space" | "events"
    >(null);
    const [selectedInternalId, setSelectedInternalId] = useState<null | string>(null)

    const [dropdownValues, setDropdownValues] = useState<{
        menu: { label: any; value: any }[];
        merch: { label: any; value: any }[];
        space: { label: any; value: any }[];
        events: { label: any; value: any }[];
    }>({ menu: [], merch: [], space: [], events: [] });

    const [modalOpen, setModalOpen] = useState<{
        defaultValue: string;
        id: string;
        type: "add" | "update";
    } | null>(null);

    const [rows, setRows] = useState([]);

    const { userState } = useUser();
    const params = useParams();

    const fetchData = async () => {
        if (!isLoading) {
            setIsLoading(true);
        }

        mobileAxios(`announcement/branch/${params.branch_id}`, {
            method: "GET",
            data: {
                adminId: userState?.admin_id_mobile,
            },
            headers: {
                Authorization: `Bearer ${userState?.admin_login_token_mobile}`,
            },
        })
            .then((res) => {
                const rowsData = res.data?.map((item: any) => ({
                    id: item._id,
                    image: item.image,
                }));

                setRows(rowsData);

                setData(res.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setData(err.message);
                setIsLoading(false);
            });
    };

    const fetchDropdownData = async () => {
        try {
            if (!isLoading) {
                setIsLoading(true);
            }
            const res = await mobileAxios(
                `/announcement/dropdown/branch-${params.branch_id}`,
                {
                    method: "GET",
                    data: {
                        adminId: userState?.admin_id_mobile,
                    },
                    headers: {
                        Authorization: `Bearer ${userState?.admin_login_token_mobile}`,
                    },
                }
            );

            let menuLeb = [];
            let menulbjson = [];
            let menu = []


            try {
                const menuLeb = await Axiosget.post("lebanon/menu.json");
                menulbjson = JSON.parse(menuLeb.data);
                menu = menulbjson[0].map((item: any) => {
                    console.log(item[0])
                    console.log()
                    return ({
                        label: `${item[0]?.["name"]}`,
                        value: item[0]?.["_id"]
                    })
                })


               
            } catch (err: any) {
              
            }



           

            let spaces: { label: any; value: any }[] = []
            let events: { label: any; value: any }[] = []

            try {
                spaces = res.data[0]?.spaces?.map((s: any) => ({
                    value: s?.["_id"],
                    label: s?.["title"],
                }));

                events = res.data[1]?.events?.map((s: any) => ({
                    value: s?.["_id"],
                    label: s?.["title"],
                }));
            } catch (error) {
                
            }

            setDropdownValues({
                menu: menu,
                merch: [],
                events: events,
                space: spaces,
            });
        } catch (error: any) {
            console.error(error);
            toast.error(error.message);
        }
    };

    useEffect(() => {
        fetchData();
        fetchDropdownData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userState]);

    const linkRef = useRef<any>();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        setIsLoading(true);

        try {
            const res = await mobileAxios(
                `/announcement/${modalOpen?.type === "update" ? modalOpen?.id : ""
                }`,
                {
                    method: modalOpen?.type === "add" ? "POST" : "PATCH",
                    data: {
                        branch: params.branch_id,
                        image: uploadedImage,
                        adminId: userState?.admin_id_mobile,
                        link_type: redirectionType,
                        link: linkRef?.current?.value ?? null,
                        internal_link: selectedInternalId ?? null,
                        internal_type: internalType ?? null
                    },
                    headers: {
                        Authorization: `Bearer ${userState?.admin_login_token_mobile}`,
                    },
                }
            );
            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }

        setUploadedImage("");
        setIsLoading(false);
        setModalOpen(null);
    };

    if (data === "loading") {
        return <Loader />;
    }

    const deleteAnnouncement = async (code_id: string) => {
        setIsLoading(true);

        try {
            const res = await mobileAxios(`/announcement/${code_id}`, {
                method: "DELETE",
                data: {
                    adminId: userState?.admin_id_mobile,
                },

                headers: {
                    Authorization: `Bearer ${userState?.admin_login_token_mobile}`,
                },
            });
            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }

        setIsLoading(false);
    };


    return (
        <div>
            {isLoading && <Loader withBg={true} />}

            <div className="flex items-center gap-8">
                <p className="font-bold text-lg mb-6 inline-block">
                    {data[0]?.name}
                </p>
                <button
                    onClick={() =>
                        setModalOpen({
                            defaultValue: "",
                            id: data[0]?._id,
                            type: "add",
                        })
                    }
                    className="py-2 px-4 mb-6 text-xs flex items-center justify-center border"
                >
                    + Add Announcement
                </button>
            </div>

            {rows?.length > 0 && (
                <div className="w-full grid md:grid-cols-4 ">
                    {rows?.map((r: any) => (
                        <EditWrapper
                            onDelete={() => deleteAnnouncement(r?.id)}
                            key={r["_id"]}
                            className="!w-full"
                        >
                            <div>
                                <img src={MEDIA_URL + r.image} alt="" />
                            </div>
                        </EditWrapper>
                    ))}
                </div>
            )}

            <Modal
                size="lg"
                isOpen={modalOpen !== null}
                toggleModal={() => setModalOpen(null)}
            >
                <form className="mt-6" onSubmit={(e) => handleSubmit(e)}>
                    <div className="input-row">
                        <Dropzone
                            defaultValue={uploadedImage}
                            handleIconUpload={(src: string) =>
                                setUploadedImage(src)
                            }
                            previewClass="w-full"
                        />

                        <div>
                            <p>Redirection type</p>
                            <div className="flex items-center">
                                <div className="flex items-center gap-2">
                                    <input
                                        onChange={() =>
                                            setredirectionType("internal")
                                        }
                                        type="radio"
                                        id="internal"
                                        name="link_type"
                                        value="internal"
                                        required
                                        defaultChecked={
                                            redirectionType === "internal"
                                        }
                                    />
                                    <label htmlFor="internal">Internal</label>
                                </div>
                                <div className="flex items-center gap-2 ml-6">
                                    <input
                                        onChange={() =>
                                            setredirectionType("external")
                                        }
                                        type="radio"
                                        id="external"
                                        name="link_type"
                                        value="external"
                                        required
                                        defaultChecked={
                                            redirectionType === "external"
                                        }
                                    />
                                    <label htmlFor="external">External</label>
                                </div>
                            </div>
                        </div>

                        <div className="mt-4">
                            {redirectionType === "internal" && (
                                <div>
                                    <div className="my-4">
                                        <div className="flex items-center gap-2 mb-2">
                                            <input
                                                onChange={() =>
                                                    setInternalType("menu")
                                                }
                                                className="!w-[20px]"
                                                type="radio"
                                                name="internal_type"
                                                value="menu"
                                                id="menu"
                                                required
                                                checked={internalType === "menu"}
                                            />
                                            <label htmlFor="menu">Menu</label>
                                        </div>
                                        {
                                            internalType === "menu" &&
                                            <Select
                                                onChange={(e) => {
                                                    setSelectedInternalId(e?.value)
                                                }}
                                                name="type"
                                                options={dropdownValues?.menu?.map(
                                                    (item) => ({
                                                        value: item.value,
                                                        label: item.label,
                                                    })
                                                )}
                                            />
                                        }
                                    </div>
                                    <div className="my-4">
                                        <div className="flex items-center gap-2 mb-2">
                                            <input
                                                onChange={() =>
                                                    setInternalType("merch")
                                                }
                                                className="!w-[20px]"
                                                type="radio"
                                                name="internal_type"
                                                value="merch"
                                                id="merch"
                                                required
                                                checked={internalType === "merch"}
                                            />
                                            <label htmlFor="merch">Merch</label>
                                        </div>
                                        {
                                            internalType === "merch" &&
                                            <Select
                                                onChange={(e) => {
                                                    setSelectedInternalId(e?.value)
                                                }}
                                                name="type"
                                                options={dropdownValues?.merch?.map(
                                                    (item) => ({
                                                        value: item.value,
                                                        label: item.label,
                                                    })
                                                )}
                                            />
                                        }

                                    </div>
                                    <div className="my-4">
                                        <div className="flex items-center gap-2 mb-2">
                                            <input
                                                onChange={() =>
                                                    setInternalType("space")
                                                }
                                                className="!w-[20px]"
                                                type="radio"
                                                name="internal_type"
                                                value="space"
                                                id="space"
                                                required
                                                checked={internalType === "space"}
                                            />
                                            <label htmlFor="space">Space</label>
                                        </div>
                                        {
                                            internalType === "space" &&
                                            <Select
                                                onChange={(e) => {
                                                    setSelectedInternalId(e?.value)
                                                }}
                                                name="type"
                                                options={dropdownValues?.space?.map(
                                                    (item) => ({
                                                        value: item.value,
                                                        label: item.label,
                                                    })
                                                )}
                                            />
                                        }
                                    </div>
                                    <div className="my-4">
                                        <div className="flex items-center gap-2 mb-2">
                                            <input
                                                onChange={() =>
                                                    setInternalType("events")
                                                }
                                                className="!w-[20px]"
                                                type="radio"
                                                name="internal_type"
                                                value="events"
                                                id="events"
                                                required
                                                checked={internalType === "events"}
                                            />
                                            <label htmlFor="events">
                                                Events
                                            </label>
                                        </div>
                                        {
                                            internalType === "events" &&
                                            <Select
                                                onChange={(e) => {
                                                    setSelectedInternalId(e?.value)
                                                }}
                                                name="type"
                                                options={dropdownValues?.events?.map(
                                                    (item) => ({
                                                        value: item.value,
                                                        label: item.label,
                                                    })
                                                )}
                                            />
                                        }
                                    </div>
                                </div>
                            )}
                            {redirectionType === "external" && (
                                <div>
                                    <input
                                        ref={linkRef}
                                        type="text"
                                        required
                                        name="link"
                                        placeholder="Redirection Url"
                                    />
                                </div>
                            )}
                        </div>

                        <input
                            type="hidden"
                            name="branch"
                            defaultValue={data[0]?._id}
                        />
                    </div>

                    <div className="flex space-x-4">
                        <Button
                            color="transparent"
                            className="ml-auto mt-8"
                            type="button"
                            onClick={() => setModalOpen(null)}
                        >
                            Cancel
                        </Button>
                        <Button className="ml-auto mt-8" type="submit">
                            Save
                        </Button>
                    </div>
                </form>
            </Modal>
        </div>
    );
};

export default MobileAnnouncements;
