import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";
import { useUser } from "../../context/UserContext";
import { mobileAxios } from "../../utils/mobileAxios";

type Props = {};

const AdminOrderDetails = (props: Props) => {
    const [data, setData] = useState<any>("loading");
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const { userState } = useUser();

    const params = useParams();

    const fetchData = async () => {
        if (!isLoading) {
            setIsLoading(true);
        }

        mobileAxios
            .get(`orders/${params.order_id}`, {
                headers: {
                    authorization: `Bearer ${userState?.admin_login_token_mobile}`,
                },
            })
            .then((res) => {
                if (res.data.length === 0) {
                    throw new Error("Nothing returned");
                }
                setData(res.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setData(err.message);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userState]);

    if (data === "loading") {
        return <Loader />;
    }

    return (
        <>
            {isLoading && <Loader withBg={true} />}
            <div>
                <h2 className="font-semibold text-xl">
                    Order Number{" "}
                    <span className="text-lightgreen">#{params.order_id}</span>
                </h2>
                <hr className="my-4" />
                <div className="flex md:flex-row flex-col  gap-8">
                    <div className="bg-white shadow-sm rounded-md border p-4 flex-1">
                        <div className="grid grid-cols-5 font-semibold">
                            <p className="col-span-2 text-xl">Items Summary</p>
                            <p className="">QTY</p>
                            <p className="">Price</p>
                            <p className="">Total Price</p>
                        </div>
                        <hr className="my-3" />

                        {data[1]?.map((item: any) => (
                            <>
                                <div
                                    className="grid grid-cols-5"
                                    key={item._id}
                                >
                                    <p className="col-span-2">
                                        {item?.menuId?.name ? (
                                            <>
                                                <span>
                                                    {item?.menuId?.name}
                                                    {" - "}
                                                </span>
                                                <span className="text-xs">
                                                    {item?.["addOns"]?.map(
                                                        (addon: any) => (
                                                            <span>
                                                                {addon.name ||
                                                                    addon.title}
                                                                : {addon.price}$
                                                                /{" "}
                                                            </span>
                                                        )
                                                    )}
                                                </span>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                        {item?.merchId?.title ? (
                                            <>
                                                <span>
                                                    {item?.merchId?.title}
                                                    {" - "}
                                                </span>
                                                <span className="text-xs">
                                                    Size:{" "}
                                                    {item?.merchVarId?.size},
                                                    Color:{" "}
                                                    {item?.merchVarId?.color}
                                                </span>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </p>
                                    <p className=""> {item?.quantity}</p>
                                    <p className="">
                                        ${" "}
                                        {item?.menuId?.price ||
                                            item?.merchVarId?.price}
                                    </p>
                                    <p className="">$ {item?.total_price}</p>
                                </div>
                                <hr className="my-4" />
                            </>
                        ))}
                    </div>
                    <div className="flex-1 max-w-sm ">
                        <div className="bg-white shadow-sm rounded-md border p-4 ">
                            <p className="font-semibold text-xl">
                                Customer Details
                            </p>

                            <div className="flex justify-between mt-4">
                                <p className="font-semibold">Full Name</p>
                                <p>{data[0]?.userId?.name}</p>
                            </div>

                            <div className="flex justify-between mt-4">
                                <p className="font-semibold">Email</p>
                                <a
                                    href={`mailto:${data[0]?.userId?.email}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {data[0]?.userId?.email}
                                </a>
                            </div>
                            <div className="flex justify-between mt-4">
                                <p className="font-semibold">Id</p>
                                <p>{data[0]?.userId?._id}</p>
                            </div>
                        </div>

                        <div className="bg-white shadow-sm rounded-md border p-4 mt-8">
                            <div className="flex justify-between items-center">
                                <p className="font-semibold text-xl">
                                    Order Summary
                                </p>
                                <div className="text-xs">
                                    <span title="Overall status">
                                        {data[0]?.status}{" "}
                                    </span>{" "}
                                    -<span title="Step ">{data[0]?.step}</span>
                                </div>
                            </div>

                            <div className="flex justify-between mt-4">
                                <p className="font-semibold">Payment with: </p>
                                <p>{data[0]?.pay_with}</p>
                            </div>
                            <div className="flex justify-between mt-4">
                                <p className="font-semibold">Order Created: </p>
                                <p>
                                    {dayjs(data[0]?.created_Date).format(
                                        "ddd DD MMM YYYY"
                                    )}
                                </p>
                            </div>

                            <div className="flex justify-between mt-2">
                                <p className="font-semibold">Order Time: </p>
                                <p>
                                    {dayjs(data[0]?.created_Date).format(
                                        "hh:mm A"
                                    )}
                                </p>
                            </div>

                            <div className="flex justify-between mt-2">
                                <p className="font-semibold">Subtotal</p>
                                <p>$ {data[0]?.total_price}</p>
                            </div>

                            <div className="flex justify-between mt-2">
                                <p className="font-semibold">Delivery fee</p>
                                <p>$ 0.00</p>
                            </div>
                        </div>

                        <div className="bg-white shadow-sm rounded-md border p-4 mt-2">
                            <div className="flex justify-between">
                                <p className="font-semibold">Order Total: </p>
                                <p>$ {data[0]?.total_price}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminOrderDetails;
