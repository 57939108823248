import React, { useState } from "react";
import { toast } from "react-toastify";

import { useUser } from "../../context/UserContext";
import { Axios } from "../../utils/axios";
import { MEDIA_URL, toast_error_settings } from "../../utils/constants";

import Dropzone from "../Dropzone";

import EditWrapper from "../EditWrapper";
import Button from "../UI/Button";
import Modal from "../UI/Modal";

type Props = {
    room_id?: string | number;
    room_name?: string;
    image?: string;
    fetchData: () => void;
    setLoading?: () => void;
    add?: boolean;
    workspace?: string;
};

const EditWorkspaceRoom = ({
    room_id,
    room_name,
    image,
    fetchData,
    setLoading,
    add = false,
    workspace,
}: Props) => {
    const { userState } = useUser();

    const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [uploadedImg, setUploadedImg] = useState<string | undefined>(image);

    const toggleEditModal = () => {
        setEditModalOpen((p) => !p);
    };

    const toggleDeleteModal = () => {
        setDeleteModalOpen((p) => !p);
    };

    const handleEditSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading && setLoading();

        const data = Object.values(e.target).reduce((obj, field) => {
            obj[field.name] = field.value;
            return obj;
        }, {});

        try {
            const res = await Axios.post(
                add ? "add_workspace_room" : "update_workspace_room",
                {
                    adminid: userState?.admin_id,
                    token: userState?.admin_login_token,
                    room_id: room_id,
                    room_name: data.room_name,
                    image: uploadedImg,
                    workspace: workspace || "",
                }
            );
            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }

        toggleEditModal();
    };

    const handleDeleteSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading && setLoading();
        try {
            const res = await Axios.post("delete_workspace_room", {
                adminid: userState?.admin_id,
                token: userState?.admin_login_token,
                room_id: room_id,
            });
            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }

        toggleDeleteModal();
    };

    return (
        <>
            <Modal
                size="2xl"
                isOpen={editModalOpen}
                toggleModal={toggleEditModal}
            >
                <form className="mt-6" onSubmit={handleEditSubmit}>
                    <div className="input-row">
                        <input
                            name="room_name"
                            type="text"
                            placeholder="Room name *"
                            defaultValue={room_name || ""}
                        />
                    </div>

                    <Dropzone
                        defaultValue={image || ""}
                        handleIconUpload={(src: string) => setUploadedImg(src)}
                        previewClass="w-full"
                    />

                    <div className="flex space-x-4">
                        <Button
                            color="transparent"
                            className="ml-auto mt-8"
                            type="button"
                            onClick={() => toggleEditModal()}
                        >
                            Cancel
                        </Button>
                        <Button className="ml-auto mt-8" type="submit">
                            Save
                        </Button>
                    </div>
                </form>
            </Modal>

            <Modal isOpen={deleteModalOpen} toggleModal={toggleDeleteModal}>
                <form className="mt-6" onSubmit={handleDeleteSubmit}>
                    <h4 className="text-center text-lg font-[600]">
                        Are you sure you want to delete this image?
                    </h4>
                    <div className="flex space-x-4">
                        <Button
                            className="ml-auto mt-8"
                            type="button"
                            onClick={() => toggleDeleteModal()}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="ml-auto mt-8 !bg-red-600 !border-red-600"
                            type="submit"
                        >
                            Delete
                        </Button>
                    </div>
                </form>
            </Modal>

            {add ? (
                <button
                    onClick={() => toggleEditModal()}
                    className="h-full min-h-[50px] w-full flex items-center justify-center border"
                >
                    + Add Room
                </button>
            ) : (
                <EditWrapper
                    onDelete={toggleDeleteModal}
                    toggleModal={toggleEditModal}
                >
                    <h2
                        className="font-semibold text-xl mb-2"
                        dangerouslySetInnerHTML={{
                            __html: room_name || "",
                        }}
                    />

                    <img
                        src={MEDIA_URL + uploadedImg}
                        alt=""
                        className={`min-h-[20px] min-w-[20px]`}
                    />
                </EditWrapper>
            )}
        </>
    );
};

export default EditWorkspaceRoom;
