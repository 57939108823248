import React, { useState } from "react";
import { toast } from "react-toastify";

import { useUser } from "../context/UserContext";
import { Axios } from "../utils/axios";
import { toast_error_settings } from "../utils/constants";

import Dropzone from "./Dropzone";

import Button from "./UI/Button";
import Modal from "./UI/Modal";

type Props = {
    fetchData: () => void;
    setLoading: () => void;
};

const AddService = ({ fetchData, setLoading }: Props) => {
    const { userState, setUser } = useUser();

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [uploadedImg, setUploadedImg] = useState<string | null>(null);

    const toggleModal = () => {
        setModalOpen((p) => !p);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading();

        const data = Object.values(e.target).reduce((obj, field) => {
            obj[field.name] = field.value;
            return obj;
        }, {});

        try {
            const res = await Axios.post("add_services", {
                adminid: userState?.admin_id,
                token: userState?.admin_login_token,
                title: data.title,
                description: data.description,
            });
            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }

        toggleModal();
    };

    return (
        <>
            <Modal size="2xl" isOpen={modalOpen} toggleModal={toggleModal}>
                <form className="mt-6" onSubmit={handleSubmit}>
                    <div className="input-row">
                        <input
                            name="title"
                            type="text"
                            placeholder="Title *"
                            required
                        />
                    </div>

                    <div className="input-row ">
                        <input
                            name="description"
                            type="text"
                            placeholder="Description *"
                            required
                        />
                    </div>

                    <div className="flex space-x-4">
                        <Button
                            color="transparent"
                            className="ml-auto mt-8"
                            type="button"
                            onClick={() => toggleModal()}
                        >
                            Cancel
                        </Button>
                        <Button className="ml-auto mt-8" type="submit">
                            Save
                        </Button>
                    </div>
                </form>
            </Modal>
            <button
                onClick={() => toggleModal()}
                className="h-fit w-full min-h-[50px] flex items-center justify-center border"
            >
                + Add Service
            </button>
        </>
    );
};

export default AddService;
