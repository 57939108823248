import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import Header from "../Shared/Header";

import { RiPagesLine } from "react-icons/ri";
import { AiOutlineHome } from "react-icons/ai";
import { MdWorkspacesOutline, MdMiscellaneousServices } from "react-icons/md";
import { CgCommunity } from "react-icons/cg";
import { BsFillCollectionFill } from "react-icons/bs";
import { TbShirt } from "react-icons/tb";
import { RiArticleLine } from "react-icons/ri";

type Props = {
    children: React.ReactNode;
};

const MainLayout = ({ children }: Props) => {
    const [sideNavOpen, setSideNavOpen] = useState<boolean>(true);

    const toggleSideNav = () => {
        setSideNavOpen((p) => !p);
    };

    return (
        <>
            <Header toggleSideNav={toggleSideNav} />
            <div
                className="flex overflow-auto"
                style={{ height: "calc(100vh - 96px)" }}
            >
                <aside
                    className={`h-full overflow-y-auto overflow-x-hidden w-fit ${
                        sideNavOpen ? "w-72" : "w-14 text-xl text-center"
                    } shadow-xl border-r transition-[width]`}
                >
                    <div className="border-b px-4 py-2 font-bold">
                        <div className="flex items-center gap-2">
                            <RiPagesLine />
                            <span className={`${sideNavOpen ? "" : "hidden"} `}>
                                Pages
                            </span>
                        </div>
                    </div>
                    <NavLink className="block px-4 py-1" to="/pages/home">
                        <div className="flex items-center gap-2">
                            <AiOutlineHome />
                            <span className={`${sideNavOpen ? "" : "hidden"} `}>
                                Home
                            </span>
                        </div>
                    </NavLink>
                    <NavLink className="block px-4 py-1" to="/pages/spaces">
                        <div className="flex items-center gap-2">
                            <MdWorkspacesOutline />
                            <span className={`${sideNavOpen ? "" : "hidden"} `}>
                                Spaces
                            </span>
                        </div>
                    </NavLink>
                    <NavLink
                        className="block px-4 py-1"
                        to="/pages/community-events"
                    >
                        <div className="flex items-center gap-2">
                            <CgCommunity />
                            <span className={`${sideNavOpen ? "" : "hidden"} `}>
                                Community & Events
                            </span>
                        </div>
                    </NavLink>

                    <NavLink className="block px-4 py-1" to="/pages/services">
                        <div className="flex items-center gap-2">
                            <MdMiscellaneousServices />
                            <span className={`${sideNavOpen ? "" : "hidden"} `}>
                                Services Providing
                            </span>
                        </div>
                    </NavLink>

                    <NavLink
                        className="block px-4 py-1"
                        to="/pages/nft-collection"
                    >
                        <div className="flex items-center gap-2">
                            <BsFillCollectionFill />
                            <span className={`${sideNavOpen ? "" : "hidden"} `}>
                                NFT Collection
                            </span>
                        </div>
                    </NavLink>

                    <NavLink
                        className="block px-4 py-1"
                        to="/pages/merchandise"
                    >
                        <div className="flex items-center gap-2">
                            <TbShirt />
                            <span className={`${sideNavOpen ? "" : "hidden"} `}>
                                Merchandise
                            </span>
                        </div>
                    </NavLink>
                    <NavLink className="block px-4 py-1" to="/pages/blogs">
                        <div className="flex items-center gap-2">
                            <RiArticleLine />
                            <span className={`${sideNavOpen ? "" : "hidden"} `}>
                                Blogs
                            </span>
                        </div>
                    </NavLink>

                    {/* <div className="border-y px-4 py-2 font-bold">
                        <div className="flex items-center gap-2">
                            <RiPagesLine />
                            <span className={`${sideNavOpen ? "" : "hidden"} `}>
                                Shared
                            </span>
                        </div>
                    </div>
                    <NavLink
                        className="block px-4 py-1"
                        to="/components/workspaces"
                    >
                        <div className="flex items-center gap-2">
                            <AiOutlineHome />
                            <span className={`${sideNavOpen ? "" : "hidden"} `}>
                                Workspaces
                            </span>
                        </div>
                    </NavLink> */}
                </aside>
                <main className="p-8 flex-1 max-h-full overflow-auto relative">
                    {children}
                </main>
            </div>
        </>
    );
};

export default MainLayout;
