import { ToastOptions } from "react-toastify";

export const MEDIA_URL = "https://backend.thebhive.io/";

export const toast_error_settings: ToastOptions<{}> = {
    position: "top-right",
    autoClose: false,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
};
