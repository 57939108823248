import React, { useState } from "react";

import EditWrapper from "../EditWrapper";
import Button from "../UI/Button";
import Modal from "../UI/Modal";

type Props = {
    title: string;
    text: string;
    button?: {
        text: string;
        href: string;
    };
    component_id: string | number;
    sectionKey: string;
    onDelete?: () => void;
    onSubmit: (
        submittedData: {
            component_title?: string;
            component_description?: string;
        },
        sectionKey: string,
        updating_component_id: string | number
    ) => void;
    titleClassName?: string;
};

const TitleWithText = ({
    component_id,
    onSubmit,
    sectionKey,
    text,
    title,
    button,
    onDelete,
    titleClassName,
}: Props) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const toggleModal = () => {
        setModalOpen((p) => !p);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        const data = Object.values(e.target).reduce((obj, field) => {
            obj[field.name] = field.value;
            return obj;
        }, {});

        onSubmit(
            {
                component_title: data.title,
                component_description: data.description,
            },
            sectionKey,
            component_id
        );
        toggleModal();
    };

    return (
        <>
            <Modal size="3xl" isOpen={modalOpen} toggleModal={toggleModal}>
                <form className="mt-6" onSubmit={handleSubmit}>
                    {title && (
                        <div className="input-row">
                            <input
                                name="title"
                                type="text"
                                placeholder="Title *"
                                defaultValue={title}
                            />
                        </div>
                    )}

                    {text && (
                        <div className="input-row">
                            <textarea
                                name="description"
                                placeholder="Description *"
                                defaultValue={text}
                                rows={4}
                            />
                        </div>
                    )}

                    <div className="flex space-x-4">
                        <Button
                            color="transparent"
                            className="ml-auto mt-8"
                            type="button"
                            onClick={() => toggleModal()}
                        >
                            Cancel
                        </Button>
                        <Button className="ml-auto mt-8" type="submit">
                            Save
                        </Button>
                    </div>
                </form>
            </Modal>
            <EditWrapper onDelete={onDelete} toggleModal={toggleModal}>
                <h2
                    className={`font-bold text-xl mb-2 ${titleClassName}`}
                    dangerouslySetInnerHTML={{
                        __html: title,
                    }}
                />
                <p
                    className="whitespace-pre-wrap"
                    dangerouslySetInnerHTML={{ __html: text }}
                />

                {button && <Button className="mt-2">{button.text}</Button>}
            </EditWrapper>
        </>
    );
};

export default TitleWithText;
