import React from "react";
import { UserProvider } from "./context/UserContext";
import { render } from "react-dom"; // add this

import "./styles/index.css";
import App from "./app";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

render(
    <React.StrictMode>
        <UserProvider>
            <ToastContainer />
            <App />
        </UserProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
 
