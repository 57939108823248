import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Loader from "../../../Components/Loader/Loader";
import { useUser } from "../../../context/UserContext";
import { mobileAxios } from "../../../utils/mobileAxios";

type Props = {};

const AdminMobileSpaces = (props: Props) => {
    const [data, setData] = useState<any>("loading");
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const { userState } = useUser();

    const fetchData = async () => {
        if (!isLoading) {
            setIsLoading(true);
        }

        mobileAxios
            .get("countries")
            .then((res) => {
                if (res.data.length === 0) {
                    throw new Error("Nothing returned");
                }
                setData(res.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setData(err.message);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userState]);

    if (data === "loading") {
        return <Loader />;
    }

    return (
        <div>
            {isLoading && <Loader withBg={true} />}
            {data?.[0]?.map((country: any) => (
                <>
                    <p className="font-bold text-lg mb-2 inline-block">
                        {country.country}
                    </p>

                    <ul className="list-disc -mt-2 ml-6">
                        {data?.[1]
                            ?.filter((b: any) => b.country === country._id)
                            ?.map((cBranch: any) => (
                                <li className="mt-2" key={cBranch._id}>
                                    <div className="flex items-center gap-2">
                                        <p className="font-semibold">
                                            {cBranch.name}
                                        </p>
                                        <NavLink
                                            className=" py-2 px-4 text-sm flex items-center justify-center border"
                                            to={`${cBranch._id}`}
                                        >
                                            View spaces
                                        </NavLink>
                                    </div>
                                </li>
                            ))}
                    </ul>

                    <hr className="my-8" />
                </>
            ))}
        </div>
    );
};

export default AdminMobileSpaces;
