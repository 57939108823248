import { useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";

import { NavLink, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Dropzone from "../../../Components/Dropzone";
import EditWrapper from "../../../Components/EditWrapper";
import Loader from "../../../Components/Loader/Loader";
import Button from "../../../Components/UI/Button";
import Modal from "../../../Components/UI/Modal";
import { useUser } from "../../../context/UserContext";
import { MEDIA_URL, toast_error_settings } from "../../../utils/constants";
import { mobileAxios } from "../../../utils/mobileAxios";

type Props = {};

const SpacesByBranchId = (props: Props) => {
    const [data, setData] = useState<any>("loading");
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [addImageRow, setAddImageRow] = useState(false);

    const [allImages, setAllImages] = useState<any>({});
    const [uploadedImg, setUploadedImg] = useState<string | undefined>(
        undefined
    );

    const [addRoomModal, setAddRoomModal] = useState<{
        title?: string;
        image?: string;
        id?: string;
        upto?: string | number;
        price?: string | number;
        features?: string;
        ideal_for?: string;
        type: "add" | "update";
    } | null>(null);
    const [deleteModal, setDeleteModal] = useState<string | null>(null);

    const { userState } = useUser();

    const params = useParams();

    const fetchData = async () => {
        if (!isLoading) {
            setIsLoading(true);
        }

        mobileAxios
            .get(`spaces/branch/${params.branch_id}`)
            .then((res) => {
                const tmpImgs: any = {};

                res.data.forEach((item: any) => {
                    tmpImgs[item._id] = item.image.split("\n");
                });

                setAllImages(tmpImgs);

                setData(res.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setData(err.message);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userState]);

    if (data === "loading") {
        return <Loader />;
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);

        const formData = Object.values(e.target).reduce((obj, field) => {
            obj[field.name] = field.value;
            return obj;
        }, {});

        try {
            const res = await mobileAxios(
                addRoomModal?.type === "add"
                    ? "spaces"
                    : `spaces/${addRoomModal?.id}`,
                {
                    method: addRoomModal?.type === "add" ? "POST" : "PATCH",
                    data: {
                        adminId: userState?.admin_id_mobile,
                        branch: params.branch_id,
                        title: formData.title,
                        image: allImages[addRoomModal?.id || "new"].join("\n"),
                        upto: formData.upto,
                        price: formData.price,
                        features: formData.features,
                        ideal_for: formData.ideal_for,
                    },
                    headers: {
                        Authorization: `Bearer ${userState?.admin_login_token_mobile}`,
                    },
                }
            );
            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }

        setIsLoading(false);
        setAddRoomModal(null);
    };

    const handleRoomDelete = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const res = await mobileAxios(`spaces/${deleteModal}`, {
                method: "DELETE",
                data: {
                    adminId: userState?.admin_id_mobile,
                },
                headers: {
                    Authorization: `Bearer ${userState?.admin_login_token_mobile}`,
                },

            });
            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }
        setIsLoading(false);
        setDeleteModal(null);
    };


    const handleUploadedImg = (
        image: string,
        id: string,
        index?: number,
        remove?: boolean
    ) => {
        const tmp_allImages: any = JSON.parse(JSON.stringify(allImages));

        if (id === "" || !id) {
            id = "new";
            if (!tmp_allImages[id]) {
                tmp_allImages[id] = [];
            }
        }

        const tmp_currImages: string[] = JSON.parse(
            JSON.stringify(tmp_allImages[id])
        );

        if (index !== undefined) {
            if (remove) {
                tmp_currImages.splice(index, 1);
            } else {
                tmp_currImages[index] = image;
            }
        } else {
            tmp_currImages.push(image);
        }

        tmp_allImages[id] = JSON.parse(JSON.stringify(tmp_currImages));

        console.log(tmp_allImages);

        setAllImages(tmp_allImages);
        setAddImageRow(false);
        setUploadedImg(undefined);
    };

    return (
        <>
            {isLoading && <Loader withBg={true} />}
            <div className="mt-4">
                {data?.map((room: any) => (
                    <div id={room.title} key={room._id}>
                        <EditWrapper
                            toggleModal={() => {
                                setAddRoomModal({
                                    type: "update",
                                    id: room._id,
                                    title: room.title,
                                    image: room.image,
                                    upto: room.upto,
                                    price: room.price,
                                    features: room.features,
                                    ideal_for: room.ideal_for,
                                });
                            }}
                            onDelete={() => setDeleteModal(room._id)}
                            className="!w-full"
                        >
                            <div className="flex gap-12 w-full" key={room._id}>
                                <div className="flex-1">
                                    <h2
                                        className="font-semibold text-xl mb-2"
                                        dangerouslySetInnerHTML={{
                                            __html: room.title || "",
                                        }}
                                    />

                                    <div className="grid grid-cols-3 gap-4">
                                        {allImages?.[room._id]?.map((img: any) => (
                                            <img
                                                src={MEDIA_URL + img}
                                                alt=""
                                                className={` h-full w-full object-contain`}
                                            />
                                        ))}
                                    </div>
                                </div>

                                <div className="flex-1 mt-8 grid grid-cols-2 gap-4">
                                    {room.upto && (
                                        <div>
                                            <h2
                                                className="font-bold text-xl mb-2 whitespace-nowrap"
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        "Up to (capacity)" ||
                                                        "",
                                                }}
                                            />
                                            <p
                                                className="whitespace-pre-wrap"
                                                dangerouslySetInnerHTML={{
                                                    __html: room.upto || "",
                                                }}
                                            />
                                        </div>
                                    )}
                                    {room.price && (
                                        <div>
                                            <h2
                                                className="font-bold text-xl mb-2 whitespace-nowrap"
                                                dangerouslySetInnerHTML={{
                                                    __html: "Price (USD/hr)",
                                                }}
                                            />
                                            <div className="flex gap-2">
                                                <p
                                                    className="whitespace-pre-wrap"
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            room.price || "",
                                                    }}
                                                />
                                                <span>$/hr</span>
                                            </div>
                                        </div>
                                    )}
                                    {room.features && (
                                        <div>
                                            <h2
                                                className="font-bold text-xl mb-2 whitespace-nowrap"
                                                dangerouslySetInnerHTML={{
                                                    __html: "Features",
                                                }}
                                            />
                                            <p
                                                className="whitespace-pre-wrap"
                                                dangerouslySetInnerHTML={{
                                                    __html: room.features || "",
                                                }}
                                            />
                                        </div>
                                    )}
                                    {room.ideal_for && (
                                        <div>
                                            <h2
                                                className="font-bold text-xl mb-2 whitespace-nowrap"
                                                dangerouslySetInnerHTML={{
                                                    __html: "Ideal For",
                                                }}
                                            />
                                            <p
                                                className="whitespace-pre-wrap"
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        room.ideal_for || "",
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </EditWrapper>
                        <hr className="my-6" />
                    </div>
                ))}

                <button
                    onClick={() => setAddRoomModal({ type: "add" })}
                    className="mt-8 h-full min-h-[50px] w-full flex items-center justify-center border"
                >
                    + Add Room
                </button>
            </div>

            <Modal
                size="2xl"
                isOpen={addRoomModal !== null}
                toggleModal={() => setAddRoomModal(null)}
            >
                <form
                    className="mt-6"
                    onSubmit={(e) => {
                        handleSubmit(e);
                    }}
                >
                    <div className="input-row">
                        <input
                            name="title"
                            type="text"
                            placeholder="Room name *"
                            defaultValue={addRoomModal?.title || ""}
                        />
                    </div>

                    <div className="grid grid-cols-3 gap-4">
                        {allImages[
                            addRoomModal?.id ? addRoomModal?.id : "new"
                        ]?.map((img: any, index: number) => (
                            <div className="flex " key={Math.random()}>
                                <Dropzone
                                    defaultValue={img || ""}
                                    handleIconUpload={(src: string) => {
                                        handleUploadedImg(
                                            src,
                                            addRoomModal?.id ?? "",
                                            index
                                        );
                                    }}
                                    previewClass="w-full"
                                />

                                <button
                                    type="button"
                                    onClick={() =>
                                        handleUploadedImg(
                                            img,
                                            addRoomModal?.id ?? "",
                                            index,
                                            true
                                        )
                                    }
                                    className="block mt-2 text-red-500"
                                >
                                    <AiFillDelete />
                                </button>
                            </div>
                        ))}
                    </div>
                    <div>
                        <button
                            type="button"
                            onClick={() => setAddImageRow((p) => !p)}
                            className="mt-2 h-full min-h-[50px] w-full flex items-center justify-center border"
                        >
                            + Add Image
                        </button>
                        {addImageRow && (
                            <div className="mt-2">
                                <Dropzone
                                    defaultValue={""}
                                    handleIconUpload={(src: string) => {
                                        setUploadedImg(src);
                                    }}
                                    previewClass="w-full"
                                />
                                <Button
                                    className="text-sm mt-2"
                                    type="button"
                                    onClick={() =>
                                        handleUploadedImg(
                                            uploadedImg ?? "",
                                            addRoomModal?.id ?? ""
                                        )
                                    }
                                >
                                    Add Image
                                </Button>
                            </div>
                        )}
                    </div>

                    <br />

                    <div className="input-row flex gap-4">
                        <input
                            name="upto"
                            type="text"
                            placeholder="Up to (capacity)"
                            defaultValue={addRoomModal?.upto || ""}
                        />

                        <input
                            name="price"
                            type="number" step="any"
                            placeholder="Price usd / hr *"
                            required
                            defaultValue={addRoomModal?.price || ""}
                        />
                    </div>

                    <div className="input-row flex gap-4">
                        <textarea
                            name="features"
                            placeholder="Features"
                            defaultValue={addRoomModal?.features || ""}
                            rows={4}
                        />

                        <textarea
                            name="ideal_for"
                            placeholder="Ideal For"
                            defaultValue={addRoomModal?.ideal_for || ""}
                            rows={4}
                        />
                    </div>

                    <div className="flex space-x-4">
                        <Button
                            color="transparent"
                            className="ml-auto mt-8"
                            type="button"
                            onClick={() => setAddRoomModal(null)}
                        >
                            Cancel
                        </Button>
                        <Button className="ml-auto mt-8" type="submit">
                            Save
                        </Button>
                    </div>
                </form>
            </Modal>

            <Modal
                isOpen={deleteModal !== null}
                toggleModal={() => setDeleteModal(null)}
            >
                <form className="mt-6" onSubmit={handleRoomDelete}>
                    <h4 className="text-center text-lg font-[600]">
                        Are you sure you want to delete this room?
                    </h4>
                    <div className="flex justify-center space-x-4">
                        <Button
                            className=" mt-8"
                            type="button"
                            onClick={() => setDeleteModal(null)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className=" mt-8 !bg-red-600 !border-red-600"
                            type="submit"
                        >
                            Delete
                        </Button>
                    </div>
                </form>
            </Modal>
        </>
    );
};

export default SpacesByBranchId;
