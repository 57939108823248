import { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";

import Loader from "../../../Components/Loader/Loader";
import { useUser } from "../../../context/UserContext";
import { mobileAxios } from "../../../utils/mobileAxios";

type Props = {};

const BookingsByRoomId = (props: Props) => {
    const [data, setData] = useState<any>("loading");
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const { userState } = useUser();

    const params = useParams();

    const fetchData = async () => {
        if (!isLoading) {
            setIsLoading(true);
        }

        mobileAxios
            .get(`/spaces/book`)
            .then((res) => {
                setData(
                    res.data.filter(
                        (s: any) => s.space_id._id === params.room_id
                    )
                );
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setData(err.message);
                setIsLoading(false);
            });
    };


    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userState]);

    if (data === "loading") {
        return <Loader />;
    }

    return (
        <>
            {isLoading && <Loader withBg={true} />}
            {data?.length === 0 ? (
                <p className="text-center mt-6 text-2xl font-bold">
                    No booking availble for this room
                </p>
            ) : (
                <div className="mt-4">
                    <div className="grid grid-cols-4 gap-4 w-full py-4 border-b ">
                        <h4 className="text-lg font-bold border-r border-r-gray-300">
                            User
                        </h4>
                        <h4 className="text-lg font-bold border-r border-r-gray-300">
                            Room
                        </h4>
                        <h4 className="text-lg font-bold border-r border-r-gray-300">
                            From{" "}
                        </h4>
                        <h4 className="text-lg font-bold border-r-gray-300">
                            Till
                        </h4>
                    </div>
                    {data?.map((book: any) => (
                        <div
                            className="grid grid-cols-4 gap-4 w-full py-4 border-b "
                            key={book._id}
                        >
                            <div className="border-r border-r-gray-300">
                                <p>
                                    <b>Name: </b> {book.user_id?.name}
                                </p>

                                <p>
                                    <b>Email: </b>
                                    <a href={`mailto:${book.user_id?.email}`}>
                                        {book.user_id?.email}
                                    </a>
                                </p>
                            </div>
                            <p className="border-r border-r-gray-300">
                                <NavLink
                                    to={`/mobile-app/spaces/${book.space_id.branch}#${book.space_id.title}`}
                                >
                                    {book.space_id.title}
                                </NavLink>
                            </p>
                            <p className="border-r border-r-gray-300">
                                {book.from_date}
                            </p>
                            <p className="border-r ">{book.till_date}</p>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default BookingsByRoomId;
