import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Dropzone from "../../Components/Dropzone";
import EditWrapper from "../../Components/EditWrapper";
import Loader from "../../Components/Loader/Loader";
import Button from "../../Components/UI/Button";
import Modal from "../../Components/UI/Modal";
import { useUser } from "../../context/UserContext";
import { MEDIA_URL, toast_error_settings } from "../../utils/constants";
import { mobileAxios } from "../../utils/mobileAxios";

import { Button as MUIButton } from "@mui/material";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import dayjs from "dayjs";

type Props = {};

function descendingComparator(a: any, b: any, orderBy: any) {
    if (
        orderBy === "id" ||
        orderBy === "color" ||
        orderBy === "type" ||
        orderBy === "name"
    ) {
        return b[orderBy]
            ?.toLowerCase()
            ?.localeCompare(a[orderBy]?.toLowerCase());
    } else if (orderBy.includes("date")) {
        return dayjs(b[orderBy]).isAfter(dayjs(a[orderBy])) ? -1 : 1;
    } else {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
}

function getComparator(order: any, orderBy: any) {
    return order === "desc"
        ? (a: any, b: any) => descendingComparator(a, b, orderBy)
        : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

const headCells = [
    {
        id: "name",
        label: "Name",
    },
    {
        id: "color",
        label: "Color",
    },
    {
        id: "type",
        label: "Type",
    },
    {
        id: "created_date",
        label: "Created Date",
    },
    {
        id: "action",
        label: "",
    },
];

function EnhancedTableHead(props: any) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: any) => (event: any) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        padding={"normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                            className="font-semibold text-base"
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const CategoriesByCountry = (props: Props) => {
    const [data, setData] = useState<any>("loading");
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [uploadedImg, setUploadedImg] = useState<string | undefined>(
        undefined
    );

    const [categoryModal, setCategoryModal] = useState<string | null | "open">(
        null
    );
    const [editModalValue, setEditModalValue] = useState<{
        id: string;
        name: string;
        type: string;
        icon: string;
        color: string;
    } | null>(null);
    const [deleteModal, setDeleteModal] = useState<string | null>(null);

    const toggleCateogryModal = () => {
        setEditModalValue(null);
        if (categoryModal === null) {
            setCategoryModal("open");
        } else {
            setCategoryModal(null);
            setUploadedImg(undefined);
        }
    };

    const [rows, setRows] = useState([]);

    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("created_date");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleRequestSort = (event: any, property: any) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const { userState } = useUser();

    const params = useParams();

    const fetchData = async () => {
        if (!isLoading) {
            setIsLoading(true);
        }

        mobileAxios
            .get(`categories`)

            .then((res) => {
                const tmp_data = res.data?.sort(
                    (a: any, b: any) =>
                        new Date(b.created_date).getTime() -
                        new Date(a.created_date).getTime()
                );

                const rowsData = tmp_data?.map((item: any) => ({
                    id: item._id,
                    icon: item.icon,
                    name: item.name,
                    color: item.color,
                    type: item.type,
                    created_date: item.created_date,
                }));

                setRows(rowsData);

                setData(tmp_data);

                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setData(err.message);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userState]);

    if (data === "loading") {
        return <Loader />;
    }

    const handleItemDelete = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const res = await mobileAxios(`categories/${deleteModal}`, {
                method: "DELETE",
                data: {
                    adminId: userState?.admin_id_mobile,
                },

                headers: {
                    Authorization: `Bearer ${userState?.admin_login_token_mobile}`,
                },

            });
            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }

        setIsLoading(false);
        setDeleteModal(null);
    };

    const handleAddNewCat = async (e: React.FormEvent) => {
        e.preventDefault();
        // setIsLoading(true);

        const formData = Object.values(e.target).reduce((obj, field) => {
            obj[field.name] = field.value;
            return obj;
        }, {});

        try {
            const res = await mobileAxios(
                `categories/${categoryModal !== null &&
                    categoryModal !== "open" &&
                    editModalValue
                    ? categoryModal
                    : ""
                }`,
                {
                    method:
                        categoryModal !== null &&
                            categoryModal !== "open" &&
                            editModalValue
                            ? "PATCH"
                            : "POST",
                    data: {
                        adminId: userState?.admin_id_mobile,
                        country: params.country_id,
                        icon: uploadedImg,
                        name: formData.name,
                        type: formData.type,
                        color: formData.color,
                    },

                    headers: {
                        Authorization: `Bearer ${userState?.admin_login_token_mobile}`,
                    },
                }
            );
            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }

        setIsLoading(false);
        toggleCateogryModal();
    };

    return (
        <>
            {isLoading && <Loader withBg={true} />}
            <div className="h-fit mb-8 flex gap-8 items-center">
                <h2 className="font-bold text-2xl">Menu items:</h2>

                <button
                    onClick={toggleCateogryModal}
                    className="px-8 py-3 flex items-center justify-center border"
                >
                    + Add Category
                </button>
            </div>

            {rows.length > 0 && (
                <Box sx={{ width: "100%" }}>
                    <Paper sx={{ width: "100%", mb: 2 }}>
                        <TableContainer>
                            <Table
                                sx={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"
                                size={"medium"}
                            >
                                <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    rowCount={rows.length}
                                    onSelectAllClick={() => { }}
                                />
                                <TableBody>
                                    {rows
                                        .sort(getComparator(order, orderBy))
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((row: any, index) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    tabIndex={-1}
                                                    key={row.id}
                                                >
                                                    <TableCell>
                                                        <div className="flex gap-3 items-center">
                                                            <img
                                                                src={
                                                                    MEDIA_URL +
                                                                    row.icon
                                                                }
                                                                alt=""
                                                                className="max-w-full h-16 w-16 object-contain bg-gray-100"
                                                            />
                                                            {row.name}
                                                        </div>
                                                    </TableCell>

                                                    <TableCell
                                                        style={{
                                                            background:
                                                                row.color,
                                                        }}
                                                    >
                                                        {row.color}
                                                    </TableCell>

                                                    <TableCell>
                                                        {row.type}
                                                    </TableCell>
                                                    <TableCell>
                                                        {dayjs(
                                                            row.created_date
                                                        ).format(
                                                            "ddd DD MMM YYYY hh:mm A"
                                                        )}
                                                    </TableCell>

                                                    <TableCell>
                                                        <MUIButton
                                                            variant="outlined"
                                                            onClick={() => {
                                                                setEditModalValue(
                                                                    {
                                                                        id: row.id,
                                                                        name: row.name,
                                                                        type: row.type,
                                                                        icon: row.icon,
                                                                        color: row.color,
                                                                    }
                                                                );
                                                                setUploadedImg(
                                                                    row.icon
                                                                );
                                                                setCategoryModal(
                                                                    row.id
                                                                );
                                                            }}
                                                        >
                                                            EDIT ITEM
                                                        </MUIButton>
                                                        <br />

                                                        <MUIButton
                                                            className="!block !mt-2"
                                                            variant="outlined"
                                                            color="error"
                                                            onClick={() =>
                                                                setDeleteModal(
                                                                    row.id
                                                                )
                                                            }
                                                        >
                                                            DELETE ITEM
                                                        </MUIButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                height: 53 * emptyRows,
                                            }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Box>
            )}

            <Modal
                size="3xl"
                isOpen={categoryModal !== null}
                toggleModal={toggleCateogryModal}
            >
                <form className="mt-6" onSubmit={handleAddNewCat}>
                    <div className="input-row">
                        <Dropzone
                            defaultValue={uploadedImg}
                            handleIconUpload={(src: string) =>
                                setUploadedImg(src)
                            }
                            previewClass="w-full"
                        />
                    </div>

                    <div className="input-row">
                        <input
                            name="name"
                            type="text"
                            placeholder="Name *"
                            required
                            defaultValue={
                                editModalValue ? editModalValue.name : ""
                            }
                        />
                    </div>

                    <div className="flex items-center space-x-2">
                        <label htmlFor="color">Icon Backgound Color: </label>
                        <input
                            type="color"
                            id="color"
                            name="color"
                            required
                            defaultValue={
                                editModalValue ? editModalValue.color : ""
                            }
                            className="border-2 rounded-md"
                        />
                    </div>

                    <div className="input-row mt-4">
                        <select
                            name="type"
                            id="type"
                            defaultValue={
                                editModalValue ? editModalValue.type : ""
                            }
                            required
                        >
                            <option value="" disabled>
                                Type:{" "}
                            </option>
                            <option value="merch">Merchandise</option>
                            <option value="menu">Menu</option>
                        </select>
                    </div>

                    <div className="flex space-x-4">
                        <Button
                            color="transparent"
                            className="ml-auto mt-8"
                            type="button"
                            onClick={toggleCateogryModal}
                        >
                            Cancel
                        </Button>
                        <Button className="ml-auto mt-8" type="submit">
                            Save
                        </Button>
                    </div>
                </form>
            </Modal>

            <Modal
                isOpen={deleteModal !== null}
                toggleModal={() => setDeleteModal(null)}
            >
                <form className="mt-6" onSubmit={handleItemDelete}>
                    <h4 className="text-center text-lg font-[600]">
                        Are you sure you want to delete this Item?
                    </h4>
                    <p className="text-red-500 mt-2 text-center font-bold text-sm">All products under this category will be deleted</p>
                    <div className="flex justify-center space-x-4">
                        <Button
                            className=" mt-8"
                            type="button"
                            onClick={() => setDeleteModal(null)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className=" mt-8 !bg-red-600 !border-red-600"
                            type="submit"
                        >
                            Delete
                        </Button>
                    </div>
                </form>
            </Modal>
        </>
    );
};

export default CategoriesByCountry;
