import React, { useState, createContext, useEffect, useContext } from "react";

interface UserType {
    admin_id: number;
    admin_id_mobile: number;
    admin_username: string;
    admin_login_token: string;
    admin_login_token_mobile: string;
}

interface IUserContext {
    userState: UserType | null;
    setUser: (data: UserType | null) => void;
} 

const UserContext = createContext<IUserContext>({
    userState: null,
    setUser: () => { },
});

export const useUser = () => {
    return useContext(UserContext);
};

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
    const [userState, setUserState] = useState<UserType | null>(null);

    useEffect(() => {
        let userInfo: string | null = localStorage.getItem("BHIVE-user");
        if (userInfo) {
            const parsedUserInfo: UserType = JSON.parse(userInfo);
            setUserState(parsedUserInfo);
        } else {
            setUserState(null);
        }
    }, []);

    const setUser = (data: UserType | null): void => {
        if (data === null) {
            setUserState(null);
            localStorage.removeItem("BHIVE-user");
            return;
        }

        setUserState(data);
        localStorage.setItem("BHIVE-user", JSON.stringify(data));
        return;
    };

    return (
        <UserContext.Provider value={{ userState, setUser }}>
            {children}
        </UserContext.Provider>
    );
};
