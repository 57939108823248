import { useState, useEffect } from "react";
import { useUser } from "../../context/UserContext";

import { findBySlug } from "../../utils/functions";

import { Axios } from "../../utils/axios";
import Loader from "../../Components/Loader/Loader";
import MerchCard from "../../Components/EditComponents/MerchCard";
import AddMerch from "../../Components/AddMerch";
import ImageEdit from "../../Components/EditComponents/Image";
import { toast } from "react-toastify";
import { toast_error_settings } from "../../utils/constants";

type Props = {};

const AdminMerch = (props: Props) => {
    const [data, setData] = useState<any>("loading");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { userState } = useUser();

    const fetchData = async () => {
        if (!isLoading) {
            setIsLoading(true);
        }

        Axios.post("build_pages", {
            adminid: userState?.admin_id,
            token: userState?.admin_login_token,
            page_id: 6,
        })
            .then((res) => {
                if (res.data.length === 0) {
                    throw new Error("Nothing returned");
                }
                setData(res.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setData(err.message);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userState]);

    if (data === "loading") {
        return <Loader />;
    }

    const handleSubmit = async (
        merch_id: string | number,
        title?: string,
        description?: string,
        price?: string | number,
        image?: string,
        category?: string
    ) => {
        setIsLoading(true);
        let isDelete: boolean = false;

        if (
            merch_id &&
            !image &&
            !title &&
            !description &&
            !price &&
            !category
        ) {
            isDelete = true;
        }

        const url: string = isDelete
            ? "delete_merchandise"
            : "update_merchandise";

        const postedData = {
            adminid: userState?.admin_id,
            token: userState?.admin_login_token,
            merchandise_id: merch_id,
            title: title,
            description: description,
            price: price,
            image: image,
            categorie: category,
        };

        try {
            const res = await Axios.post(url, postedData);
            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }
    };

    const handleNormalSubmit = async (
        submittedData: {
            component_title?: string;
            component_description?: string;
        },
        sectionKey: string,
        updating_component_id: string | number
    ) => {
        setIsLoading(true);
        const updated_components = data[sectionKey].map((item: any) => {
            if (
                item.component_id.toString() ===
                updating_component_id.toString()
            ) {
                return {
                    ...item,
                    component_title: submittedData.component_title,
                    component_description:
                        submittedData.component_description || "",
                };
            } else {
                return {
                    ...item,
                    component_title: item.title,
                    component_description: item.description || "",
                };
            }
        });

        try {
            const res = await Axios.post("update_component", {
                adminid: userState?.admin_id,
                token: userState?.admin_login_token,
                components: updated_components,
            });
            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }
    };

    return (
        <div>
            {isLoading && <Loader withBg={true} />}

            <div className="w-full">
                <ImageEdit
                    component_id={
                        findBySlug(data["hero"], "image").component_id
                    }
                    sectionKey="hero"
                    src={findBySlug(data["hero"], "image").title}
                    onSubmit={handleNormalSubmit}
                />
            </div>

            <hr className="my-10" />

            <div className="grid grid-cols-2 lg:grid-cols-3 gap-3">
                {data["merchandise"].map((merch: any) => (
                    <div key={merch.merchandise_id}>
                        <MerchCard
                            merch_id={merch.merchandise_id}
                            title={merch.title}
                            description={merch.description}
                            price={merch.price}
                            image={merch.image}
                            handleUpdate={handleSubmit}
                            category={merch.categorie}
                        />
                    </div>
                ))}
            </div>
            <br />

            <AddMerch
                fetchData={fetchData}
                setLoading={() => setIsLoading(true)}
            />

            <hr className="my-10" />
        </div>
    );
};

export default AdminMerch;
